import { ContentHubItemElement } from "../../../../Services/types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../../../Services/types/ContentHub/ContentHubListsElement";
import { ContentHubSiteElement } from "../../../../Services/types/ContentHub/ContentHubSiteElement";
import { BadDebt } from "../../../../Services/types/ContentHub/Credlt/BadDebt";
import { ContentHubUser } from "../../../../Services/types/General/user";
import { ContentHubListRegistry } from "../../SitesRegistry";
import { FormBadDebt } from "./BadDebtForm";

export const BadDebtListRegistry: ContentHubListRegistry = {
  name: "BadDebt",
  header: "Bad Debt",
  columns: [
    { key: "id", displayName: "ID", visible: false },
    { key: "counterparty", displayName: "Counterparty" },
    { key: "accountNumber", displayName: "Account Number" },
    { key: "requestor", displayName: "Requestor" },
    { key: "requestStatus", displayName: "Request Status" },
  ],
  mapper: function (item: ContentHubItemElement<BadDebt>): Record<string, any> {
    var requestor = item.Fields.requestor.Value as ContentHubUser;
    return {
      id: item.Id,
      counterparty: item.Fields.counterparty?.Value,
      accountNumber: item.Fields.accountNumber?.Value,
      requestor: requestor.Name,
      requestStatus: item.Fields.requestStatus.Value,
    };
  },
  renderForm: function (
    siteDefinition: ContentHubSiteElement,
    listDefinition: ContentHubListsElement,
    selectedItem: ContentHubItemElement<BadDebt>,
    drawerOpen: boolean,
    onDrawerClosed: (result: BadDebt | null) => void
  ): JSX.Element {
    return (
      <FormBadDebt
        site={siteDefinition}
        list={listDefinition}
        itemDefinition={selectedItem}
        drawerOpen={drawerOpen!}
        onDrawerClosed={onDrawerClosed}
      />
    );
  },
};
