import { Field, Label } from "@fluentui/react-components"
import { Control, FieldValues, useController, Path } from "react-hook-form"
import AsyncSelect from "react-select/async"
import { getSelectStyle, getSelectTheme } from "../CommonSelect/CommonSelect"
import { useContext, useCallback, useState, useEffect, useRef } from "react"
import { TeamsFxContext } from "../../Context"
import { useDebouncedCallback } from "use-debounce"
import { CounterpartyService } from "../../../Services/general/counterparty"
import { Counterparty } from "../../../Services/types/General/Counterparty"


export const SelectCounterparty = <T extends FieldValues>({ label, showInfo, defaultCounterparty, control, name, handleCounterpartySelection, required }: {
    label: string,
    showInfo?: boolean | undefined,
    defaultCounterparty?: Counterparty | undefined
    control: Control<T>,
    name: Path<T>,
    required?: boolean
    handleCounterpartySelection: (counterparty: Counterparty) => void
}) => {
    const { teamsUserCredential } = useContext(TeamsFxContext);
    const { themeString } = useContext(TeamsFxContext);
    const { field } = useController({
        control,
        name
    });
    const [requiredField, setRequiredField] = useState(required);

    useEffect(() => {

    }, [])

    const searchCallback = useCallback(
        useDebouncedCallback((inputValue, callback) => {
            searchCounterparties(inputValue).then(options => callback(options))
        }, 1000),
        []
    );

    const [selectedCounterparty, setSelectedCounterparty] = useState<Counterparty | undefined>(defaultCounterparty);
    const [counterparties, setCounterparties] = useState<Counterparty[]>([])
    const searchCounterparties = async (counterpartySearch: string) => {
        try {
            var response = await CounterpartyService.getCounterparties(teamsUserCredential!);


            var filter = response.filter((counterparty) => counterparty.Name.toLocaleLowerCase()
                .includes(counterpartySearch.toLocaleLowerCase()))
            setCounterparties(counterparties)
            return filter;

        }
        catch (ex) {
            console.log(ex)
        }
    }

    var defaultValue: Counterparty = {
        Name: '',
        Sic: '',
        CounterpartyClass: '',
        CounterpartyId: 0,
        Status: ''
    };;
    const onChangeSelect = (data: Counterparty) => {
        if (data !== null) {
            setSelectedCounterparty(data);
            handleCounterpartySelection(data);
        }
        else{
            setSelectedCounterparty(defaultValue);
            handleCounterpartySelection(defaultValue);
        }
        //setRequiredField(false);
    }

    return (
        <div>
            <Field label={label} required={requiredField !== undefined ? requiredField : false}>
                <AsyncSelect
                    cacheOptions={true}
                    {...field}
                    styles={getSelectStyle(themeString)}
                    theme={getSelectTheme(themeString)}
                    loadOptions={searchCallback}
                    getOptionValue={option => `${option.Name}`}
                    getOptionLabel={option => `${option.Name}`}
                    onChange={onChangeSelect}
                    value={counterparties.find((c) => c.Name === field.value)}
                    defaultValue={selectedCounterparty?.Name !== '' ? selectedCounterparty : undefined}
                    isClearable={true}
                    noOptionsMessage={() => "Search for a Counter Party"}
                />
            </Field>
            {
                ((showInfo !== undefined && showInfo) && selectedCounterparty !== undefined && selectedCounterparty.Name !== "") &&
                <div className="counterpartyInfoDiv">
                    <div className="counterpartyIdDiv">
                        <Label>Counterparty ID: {selectedCounterparty.CounterpartyId}</Label>
                    </div>

                    <div className="counterpartySicDiv">
                        <Label>SIC Code: {selectedCounterparty.Sic}</Label>
                    </div>
                    <div className="counterpartyClassDiv">
                        <Label>Class Type: {selectedCounterparty.CounterpartyClass}</Label>
                    </div>
                </div>
            }
        </div>
    )
}