import { useForm, SubmitHandler, Controller, useWatch } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import {
  SelectTabData,
  SelectTabEvent,
  Switch,
  Text,
  Tab,
  TabList,
  TabValue,
  OverlayDrawer,
  ProgressBar,
  DialogOpenChangeEvent,
  DialogOpenChangeData,
  DrawerHeader,
  DrawerHeaderTitle,
  Button,
  DrawerBody,
  Field,
  Input,
  Label,
  Textarea,
  MessageBar,
  MessageBarTitle,
  MessageBarBody,
  SpinButton,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "@fluentui/react-datepicker-compat";

//Internal
import {
  DropDownData,
  dropDownToString,
} from "../../../../Services/types/General/DropDownData";
import { Counterparty } from "../../../../Services/types/General/Counterparty";
import { ContentHubItemElement } from "../../../../Services/types/ContentHub/ContentHubItemElement";
import { TeamsFxContext } from "../../../Context";
import {
  BadDebt,
  BadDebtSchema,
} from "../../../../Services/types/ContentHub/Credlt/BadDebt";
import { ContentHubSiteElement } from "../../../../Services/types/ContentHub/ContentHubSiteElement";
import { ContentHubListsElement } from "../../../../Services/types/ContentHub/ContentHubListsElement";
import useContentHubForm from "../../../../Hooks/useContentHubForm";
import "../BadDebt/BadDebt.css";
import { SelectPerson } from "../../../CommonComponents/selectPerson/SelectPerson";
import { SelectDropDownData } from "../../../CommonComponents/SelectDropDownData/SelectDropDownData";
import { SelectCounterparty } from "../../../CommonComponents/SelectCounterparty/SelectCounterparty";
import { formatDate } from "../../../../utils/utils";
import { ApprovalHistory } from "../../../ApprovalTab/ApprovalHistory/ApprovalHistory";
import { NumericInput } from "../../../CommonComponents/NumericInput/NumericInput";
import { UserContext } from "../../../../Context/UserContext";
import { UserService } from "../../../../Services/general/user";

const defaultForm: Record<string, string | any> = {
  counterparty: "",
  counterpartyId: "",
  accountNumber: "",
  rmAccountStatus: "",
  collector: undefined,
  glNumber: "110505",
  electricity: 0,
  gas: 0,
  terminationValue: 0,
  writeOffTotal: 0,
  writeOffSummary: "",
  creditApprovalDetails: "",
  reasonCode: "",
  fillingDate: null,
  eventRelated: false,
  eventDetails: "",
  salesPerson: undefined,
  rvp: undefined,
  region: "",
  counterpartyClass: "",
  sicCode: "",
  reserved: "",
  requestor: undefined,
  requestStatus: "Pending",
  // 'managerCreditRisk': null,
  // 'managerCreditRiskReview': null,
  // 'managerCreditRiskApprovalDate': null,
  // 'managerCreditRiskComments': '',
  // 'managingDirectorCreditRisk': null,
  // 'managingDirectorCreditRiskReview': null,
  // 'managingDirectorCreditRiskApprovalDate': null,
  // 'managingDirectorCreditRiskComments': '',
  // 'svp': null,
  // 'svpReview': null,
  // 'svpApprovalDate': null,
  // 'svpComments': '',
  // 'president': null,
  // 'presidentReview': null,
  // 'presidentApprovalDate': null,
  // 'presidentComments': '',
  accountingReview: null,
  accountingStatus: "Pending",
  accountingComments: "",
  journalEntryNumber: null,
  dateCompleted: null,
};

export const FormBadDebt = ({
  site,
  list,
  itemDefinition,
  drawerOpen,
  onDrawerClosed,
}: {
  site: ContentHubSiteElement | undefined;
  list: ContentHubListsElement | undefined;
  itemDefinition: ContentHubItemElement<BadDebt> | null | undefined;
  drawerOpen: boolean;
  onDrawerClosed: (result: BadDebt | null) => void;
}) => {
  const { userInfo } = useContext(UserContext);
  const [title, setTitle] = useState<string>("New Bad Debt");
  const [editing, setEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>();

  //Dropdown Options
  const [rmStatus, setrmStatus] = useState<DropDownData[]>([]);
  const [glOptions, setglOptions] = useState<DropDownData[]>([]);
  const [reasonCodeOptions, setReasonCodeOptions] = useState<DropDownData[]>(
    []
  );
  const [regionOptions, setRegionOptions] = useState<DropDownData[]>([]);
  const [reservedOptions, setReservedOptions] = useState<DropDownData[]>([]);
  const [accountingStatusOptions, setAccountingStatusOptions] = useState<
    DropDownData[]
  >([]);

  //Drawer Methods
  const closeDrawer = (result: BadDebt | null) => {
    // if (isDirty && !isSubmitSuccessful) {
    //     alert("Close Without Saving?");
    // }
    onDrawerClosed(result);
  };

  const onOpenChange = (
    _event: DialogOpenChangeEvent,
    data: DialogOpenChangeData
  ) => {
    if (data.open === false) {
      closeDrawer(null);
    }
  };

  //Drawer open
  useEffect(() => {
    if (drawerOpen === true) {
      if (userInfo != undefined) {
        let user = UserService.userProfileToContentHubUser(userInfo);
        console.log(user);
        if (user !== undefined) {
          setValue("requestor", user);
        }
      }
      //Load Options for DropDowns
      setrmStatus(getOptions("rmAccountStatus"));
      setglOptions(getOptions("glNumber"));
      setReasonCodeOptions(getOptions("reasonCode"));
      setRegionOptions(getOptions("region"));
      setReservedOptions(getOptions("reserved"));
      setAccountingStatusOptions(getOptions("accountingStatus"));
      setItemForm(itemDefinition);
    } else {
      setEditing(false);
      reset(defaultForm);
      setSubmitStatus(null);
      setSelectedTab("request");
      setGas(0.0);
      setTermination(0.0);
      setElectricity(0.0);
    }
  }, [drawerOpen]);

  //Form Methods
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { isValid, errors, isSubmitSuccessful, isDirty, dirtyFields },
  } = useForm<BadDebt>({
    mode: "all",
    resolver: yupResolver<BadDebt>(BadDebtSchema),
  });
  const status = getValues("requestStatus");

  useEffect(() => {
    if (errors !== undefined && errors !== null) {
      console.log(errors);
    }
  }, [errors]);

  //Form Options
  const {
    item,
    setItemForm,
    submitStatus,
    beingSubmitted,
    setSubmitStatus,
    submitHandler,
    getOptions,
  } = useContentHubForm({
    siteDefinition: site,
    listDefinition: list,
    itemDefinition: itemDefinition,
  });
  const correlationKey = site?.Id + "/" + list?.Id + "/" + item?.Id;
  //Load Item Selected
  useEffect(() => {
    loadSelected();
  }, [item]);

  const loadSelected = async () => {
    if (item !== null && item !== undefined) {
      setTitle("Editing Bad Debt Request");
      setEditing(true);
      //Info Load
      const infoEdit: BadDebt = {
        counterparty: item.Fields.counterparty.Value,
        counterpartyId: item.Fields.counterpartyId.Value,
        accountNumber: item.Fields.accountNumber.Value,
        rmAccountStatus: item.Fields.rmAccountStatus.Value,
        collector: item.Fields.collector.Value,
        glNumber: item.Fields.glNumber.Value,
        electricity: item.Fields.electricity.Value,
        gas: item.Fields.gas.Value,
        terminationValue: item.Fields.terminationValue.Value,
        writeOffTotal: item.Fields.writeOffTotal.Value,
        writeOffSummary: item.Fields.writeOffSummary.Value,
        creditApprovalDetails: item.Fields.creditApprovalDetails.Value,
        reasonCode: item.Fields.reasonCode.Value,
        fillingDate:
          item.Fields.fillingDate?.Value !== null
            ? new Date(item.Fields.fillingDate?.Value!)
            : null,
        eventRelated: item.Fields.eventRelated.Value,
        eventDetails: item.Fields.eventDetails.Value,
        salesPerson: item.Fields.salesPerson.Value,
        rvp: item.Fields.rvp.Value,
        region: item.Fields.region.Value,
        counterpartyClass: item.Fields.counterpartyClass.Value,
        sicCode: item.Fields.sicCode.Value,
        reserved: item.Fields.reserved.Value,
        requestor: item.Fields.requestor.Value,
        requestStatus: item.Fields.requestStatus.Value,
        // 'managerCreditRisk': item.Fields.managerCreditRisk?.Value,
        // 'managerCreditRiskReview': item.Fields.managerCreditRiskReview?.Value,
        // 'managerCreditRiskApprovalDate': item.Fields.managerCreditRiskApprovalDate?.Value,
        // 'managerCreditRiskComments': item.Fields.managerCreditRiskComments?.Value,
        // 'managingDirectorCreditRisk': item.Fields.managingDirectorCreditRisk?.Value,
        // 'managingDirectorCreditRiskReview': item.Fields.managingDirectorCreditRiskReview?.Value,
        // 'managingDirectorCreditRiskApprovalDate': item.Fields.managingDirectorCreditRiskApprovalDate?.Value,
        // 'managingDirectorCreditRiskComments': item.Fields.managingDirectorCreditRiskComments?.Value,
        // 'svp': item.Fields.svp?.Value,
        // 'svpReview': item.Fields.svpReview?.Value,
        // 'svpApprovalDate': item.Fields?.svpApprovalDate?.Value,
        // 'svpComments': item.Fields.svpComments?.Value,
        // 'president': item.Fields.president?.Value,
        // 'presidentReview': item.Fields.presidentReview.Value,
        // 'presidentApprovalDate': item.Fields.presidentApprovalDate?.Value,
        // 'presidentComments': item.Fields.presidentComments.Value,
        accountingReview: item.Fields.accountingReview?.Value,
        accountingStatus: item.Fields.accountingStatus.Value,
        accountingComments: item.Fields.accountingComments.Value,
        journalEntryNumber: item.Fields.journalEntryNumber!.Value,
        dateCompleted:
          item.Fields.dateCompleted?.Value !== null
            ? new Date(item.Fields.dateCompleted?.Value!)
            : null,
      };
      reset(infoEdit);

      setGas(getValues("gas"));
      setElectricity(getValues("electricity"));
      setTermination(getValues("terminationValue"));
    } else {
      setTitle("New Bad Debt Request");
      reset(defaultForm);

      if (userInfo != undefined) {
        let user = UserService.userProfileToContentHubUser(userInfo);
        console.log(user);
        if (user !== undefined) {
          setValue("requestor", user);
        }
      }
    }
  };

  const submitForm: SubmitHandler<BadDebt> = async (data: BadDebt) => {
    console.log(data);
    try {
      if (editing) {
        await submitHandler(data, item?.Id, editing);
      } else {
        await submitHandler(data, null, editing);
      }
    } catch (ex) {
      console.log("Failed Submit");
    }
  };

  //Set Required For Filling Date
  const reasonCodeSelected = watch("reasonCode");
  const [fillingDateRequired, setFillingDateRequired] =
    useState<boolean>(false);
  useEffect(() => {
    const optionSelected = dropDownToString(reasonCodeSelected);
    setFillingDateRequired(optionSelected === "BKY" ? true : false);
    trigger("fillingDate", { shouldFocus: true });
  }, [reasonCodeSelected]);

  //Set Required for Event Details
  const eventRelated = watch("eventRelated");
  const [eventDetailsRequired, setEventDetailsRequired] =
    useState<boolean>(false);
  useEffect(() => {
    setEventDetailsRequired(eventRelated);
    trigger("eventDetails", { shouldFocus: true });
  }, [eventRelated]);

  //Calculate Write Off
  const [electricity, setElectricity] = useState<number>(0.0);
  const [gas, setGas] = useState<number>(0.0);
  const [termination, setTermination] = useState<number>(0.0);
  const [totalWriteOff, setTotalWriteOff] = useState<number>(0);

  const sumValue = electricity + +gas + +termination;

  useEffect(() => {
    setValue("electricity", electricity);
    setValue("gas", gas);
    setValue("terminationValue", termination);

    setTotalWriteOff(sumValue);
    setValue("writeOffTotal", sumValue);
  }, [sumValue]);

  //DropDowns Info Counterparties
  var defaultCounterparty: Counterparty = {
    Name:
      itemDefinition?.Fields.counterparty !== undefined
        ? itemDefinition?.Fields.counterparty.Value
        : "",
    Sic:
      itemDefinition?.Fields.sicCode !== undefined
        ? itemDefinition?.Fields.sicCode.Value
        : "",
    CounterpartyClass:
      itemDefinition?.Fields.counterpartyClass !== undefined
        ? itemDefinition?.Fields.counterpartyClass.Value
        : "",
    CounterpartyId:
      itemDefinition?.Fields.counterpartyId !== undefined
        ? +itemDefinition?.Fields.counterpartyId.Value
        : 0,
    Status: "ACTIVE",
  };
  const handleCounterparty = (counterparty: Counterparty | undefined) => {
    if (counterparty !== undefined) {
      setValue("counterparty", counterparty.Name);
      setValue("counterpartyId", counterparty.CounterpartyId.toString());
      setValue("sicCode", counterparty.Sic);
      setValue("counterpartyClass", counterparty.CounterpartyClass);
    } else {
    }
  };

  //Handle Set to Submit
  const submitRequestToWorkflow = () => {
    setValue("requestStatus", "Ready to submit");
    submitForm(getValues());
  };

  //Tabs
  const [selectedTab, setSelectedTab] = useState<TabValue>("request");
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTab(data.value);
  };

  return (
    <div key="badDebtFormDiv">
      <OverlayDrawer
        size="large"
        position="end"
        modalType="alert"
        open={drawerOpen}
        onOpenChange={onOpenChange}
      >
        {submitStatus !== null ? (
          submitStatus == true ? (
            <div className="submitMessage">
              <MessageBar key="info-attachments" intent="success">
                <MessageBarBody>
                  <MessageBarTitle>Submit Success</MessageBarTitle>
                  Information submitted succesfully to database.
                </MessageBarBody>
              </MessageBar>
            </div>
          ) : (
            <div className="submitMessage">
              <MessageBar key="info-attachments" intent="error">
                <MessageBarBody>
                  <MessageBarTitle>Submit Error</MessageBarTitle>
                  Unexpected error when trying to submit information
                </MessageBarBody>
              </MessageBar>
            </div>
          )
        ) : (
          <div></div>
        )}

        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular></Dismiss24Regular>}
                onClick={() => {
                  closeDrawer(null);
                }}
              ></Button>
            }
          >
            {title}
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          {drawerOpen && !isLoading ? (
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="submitbutton">
                {beingSubmitted === false ? (
                  <Button
                    // disabled={!isValid}
                    appearance="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                ) : (
                  <div style={{ marginTop: "10px" }}>
                    <Field
                      validationMessage="Please Waiit... Saving and Uploading Files."
                      validationState="none"
                    >
                      <ProgressBar />
                    </Field>
                  </div>
                )}
              </div>

              {editing && (
                <div className="statusDiv">
                  <div className="status">
                    <h2>
                      <Label
                        htmlFor="statusId"
                        style={{ color: "" }}
                        className="statusLabel"
                      >
                        Status : {status}
                      </Label>
                    </h2>
                    {status === "Request Completed" && (
                      <div style={{ marginTop: "10px" }}>
                        <Label>
                          Date Completed:
                          {formatDate(
                            item?.Fields?.dateCompleted?.Value
                              ? item.Fields.dateCompleted.Value
                              : new Date()
                          )}
                        </Label>
                      </div>
                    )}
                    {status === "Pending" && (
                      <Button
                        appearance="primary"
                        onClick={submitRequestToWorkflow}
                      >
                        Submit Request
                      </Button>
                    )}
                  </div>
                </div>
              )}

              <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                <Tab id="request" value="request">
                  Request
                </Tab>
                {editing &&
                  (status === "Approved for Accounting Review" ||
                    status === "Request Completed") && (
                    <Tab id="accounting" value="accounting">
                      Accounting
                    </Tab>
                  )}
                {editing && (
                  <Tab id="approvals" value="approvals">
                    Approval History
                  </Tab>
                )}
              </TabList>

              {selectedTab === "approvals" && (
                <div style={{ marginTop: "10px" }}>
                  <ApprovalHistory correlationKey={correlationKey} />
                </div>
              )}

              {selectedTab == "request" && (
                <div className="requestArea">
                  {/* CounterpartyName */}
                  <div className="counterpartyDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="counterparty" />
                    </div>
                    <SelectCounterparty
                      label="Counterparty"
                      showInfo={true}
                      defaultCounterparty={defaultCounterparty}
                      control={control}
                      name="counterparty"
                      handleCounterpartySelection={handleCounterparty}
                      required={true}
                    />
                  </div>

                  {/* Account Number */}
                  <div className="accountNumberDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="accountNumber" />
                    </div>
                    <Field label="Account Number" required>
                      <Controller
                        name="accountNumber"
                        control={control}
                        render={({ field }) => <Input {...field} type="text" />}
                      />
                    </Field>
                  </div>

                  {/* RM Account Status */}
                  <div className="rmAccountStatusDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="rmAccountStatus" />
                    </div>
                    <SelectDropDownData
                      label="RM Account Status"
                      options={rmStatus}
                      control={control}
                      name="rmAccountStatus"
                      required={true}
                    />
                  </div>

                  {/* Collector */}
                  <div className="collectorDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="collector" />
                    </div>
                    <SelectPerson
                      label="Collector"
                      group="SGG_NES_CREDITRISK"
                      control={control}
                      name="collector"
                      required={true}
                    />
                  </div>

                  {/* GL Number */}
                  <div className="glNumberDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="glNumber" />
                    </div>
                    <SelectDropDownData
                      label="GL Number"
                      options={glOptions}
                      control={control}
                      name="glNumber"
                      required={true}
                    />
                  </div>

                  {/* Electricity */}
                  <div className="electricityDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="electricity" />
                    </div>
                    <NumericInput
                      label="Electricity"
                      control={control}
                      name="electricity"
                      defaultValue={electricity}
                      handleChange={(data) => setElectricity(data)}
                      isMoney={true}
                    />
                  </div>

                  {/* Gas */}
                  <div className="gasDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="gas" />
                    </div>
                    <NumericInput
                      label="Gas"
                      control={control}
                      name="gas"
                      defaultValue={gas}
                      handleChange={(data) => setGas(data)}
                      isMoney={true}
                    />
                  </div>

                  {/* Termination Value */}
                  <div className="terminationValueDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="terminationValue" />
                    </div>
                    <NumericInput
                      label="Termination Value"
                      control={control}
                      name="terminationValue"
                      defaultValue={termination}
                      handleChange={(data) => setTermination(data)}
                      isMoney={true}
                    />
                  </div>

                  {/* Write Off Total */}
                  <div className="writeOffTotalDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="writeOffTotal" />
                    </div>
                    <Field label="Write Off Total">
                      <Label>
                        <b>$ {totalWriteOff}</b>
                      </Label>
                    </Field>
                  </div>

                  {/* Write Off Summary */}
                  <div className="writeOffSummaryDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="writeOffSummary" />
                    </div>
                    <Field label="Write Off Summary" required>
                      <Controller
                        name="writeOffSummary"
                        control={control}
                        render={({ field }) => (
                          <Textarea
                            {...field}
                            className="writeOffSummaryInput"
                            key="writeOffSummaryKey"
                            rows={3}
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Credit Approval Details */}
                  <div className="creditApprovalDetailsDiv">
                    <div className="errorMessage">
                      <ErrorMessage
                        errors={errors}
                        name="creditApprovalDetails"
                      />
                    </div>
                    <Field label="Credit Approval Details" required>
                      <Controller
                        name="creditApprovalDetails"
                        control={control}
                        render={({ field }) => (
                          <Textarea
                            {...field}
                            className="creditApprovalDetailsInput"
                            key="creditApprovalDetailsKey"
                            rows={3}
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Reason Code */}
                  <div className="reasonCodeDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="reasonCode" />
                    </div>
                    <SelectDropDownData
                      label="Reason Code"
                      options={reasonCodeOptions}
                      control={control}
                      name="reasonCode"
                      required={true}
                    />
                  </div>

                  {/* Filling Date */}
                  <div className="fillingDateDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="fillingDate" />
                    </div>
                    <Field label="Filling Date" required={fillingDateRequired}>
                      <Controller
                        name="fillingDate"
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                          <DatePicker
                            //onOpenChange={onOpen}
                            onSelectDate={onChange}
                            ref={ref}
                            placeholder="Select a date..."
                            value={value}
                            formatDate={(value) => {
                              return value !== undefined
                                ? formatDate(value)
                                : "";
                            }}
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Event Related */}
                  <div className="eventRelatedDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="eventRelated" />
                    </div>
                    <Field label="Event Related">
                      <Controller
                        name="eventRelated"
                        control={control}
                        render={({ field: { value, name, ref, onChange } }) => (
                          <Switch
                            name="name"
                            checked={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Event Details */}
                  <div className="eventDetailsDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="eventDetails" />
                    </div>
                    <Field
                      label="Event Details"
                      required={eventDetailsRequired}
                    >
                      <Controller
                        name="eventDetails"
                        control={control}
                        render={({ field }) => (
                          <Textarea
                            {...field}
                            className="eventDetailsInput"
                            key="eventDetailsKey"
                            rows={3}
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Salesperson */}
                  <div className="salespersonDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="salesPerson" />
                    </div>
                    <SelectPerson
                      label="Sales Person"
                      control={control}
                      group="SGG_NES_SALES"
                      name="salesPerson"
                      required={true}
                    />
                  </div>

                  {/* RVP */}
                  <div className="rvpDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name='rvp' />
                    </div>
                    <SelectPerson
                      label="RVP"
                      control={control}
                      group="SGG_NES_SALES"
                      name="rvp"
                      required={true}
                    />
                  </div>

                  {/* Region */}
                  <div className="regionDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="region" />
                    </div>
                    <SelectDropDownData
                      label="Region"
                      options={regionOptions}
                      control={control}
                      name="region"
                      required={true}
                    />
                  </div>

                  {/* Reserved */}
                  <div className="reservedDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="reserved" />
                    </div>
                    <SelectDropDownData
                      label="Reserved"
                      options={reservedOptions}
                      control={control}
                      name="reserved"
                      required={true}
                    />
                  </div>
                </div>
              )}
              {selectedTab == "accounting" && (
                <div className="accountingArea">
                  {/* Accounting Reviewd By */}
                  <div className="accountingReviewDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="accountingReview" />
                    </div>
                    <SelectPerson
                      label="Accounting Reviewed By"
                      control={control}
                      name="accountingReview"
                      group="SGG_NES_COMMODITYACCOUNTING"
                      required={true}
                    />
                  </div>

                  {/* Accounting Status */}
                  <div className="accountingStatusDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="accountingStatus" />
                    </div>
                    <SelectDropDownData
                      label="Accounting Review Status"
                      options={accountingStatusOptions}
                      control={control}
                      name="accountingStatus"
                      required={true}
                    />
                  </div>

                  {/* Accounting Comments */}
                  <div className="accountingCommentsDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="accountingComments" />
                    </div>
                    <Field label="Accounting Comments">
                      <Controller
                        name="accountingComments"
                        control={control}
                        render={({ field }) => (
                          <Textarea
                            {...field}
                            className="accountingCommentsInput"
                            key="accountingCommentsKey"
                            rows={3}
                          />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Journal Entry Number */}
                  <div className="journalEntryDateDiv">
                    <div className="errorMessage">
                      <ErrorMessage errors={errors} name="journalEntryNumber" />
                    </div>
                    <Field label="Journal Entry Number">
                      <Controller
                        name="journalEntryNumber"
                        control={control}
                        render={({ field }) => (
                          <Input {...field} type="number" min={0} />
                        )}
                      />
                    </Field>
                  </div>

                  {/* Date Completed */}
                  <div className="completedDateDiv"></div>
                </div>
              )}
            </form>
          ) : (
            <div></div>
          )}
        </DrawerBody>
      </OverlayDrawer>
    </div>
  );
};
