import { Field, SpinButton, SpinButtonProps, useId } from "@fluentui/react-components";
import { Control, FieldValues, useController, Path } from "react-hook-form"
import { useContext, useState, useEffect } from "react"

type FormatterFn = (value: number) => string;
type ParserFn = (formattedValue: string) => number;

export const NumericInput = <T extends FieldValues>({
  label,
  control,
  isMoney,
  name,
  defaultValue,
  minValue,
  decimals,
  required,
  handleChange
}: {
  label: string,
  isMoney?: boolean,
  control: Control<T>,
  name: Path<T>,
  defaultValue: number,
  minValue?: number
  decimals?: number,
  required?: boolean,
  handleChange?: (spinButtonValue: number) => void
}) => {
  const [requiredField, setRequiredField] = useState(required);
  const { field } = useController({
    control,
    name,
  });

  const formatter: FormatterFn = (value) => {
    if (isMoney)
      return `$${value}`;
    else
      return `${value}`;
  };

  const parser: ParserFn = (formattedValue) => {
    if (formattedValue === null) {
      return NaN;
    }

    var replace = "";
    if(isMoney)
      replace = "$";

    const value = parseFloat(formattedValue.replace(replace, ""));
    const roundedValue = value % 1 === 0 ? value : Math.round((value + Number.EPSILON) * 100) / 100;
    return roundedValue;
  };

  const onSpinButtonChange: SpinButtonProps["onChange"] = (_ev, data) => {
    if (data.value !== undefined && data.value !== null) {
      setSpinButtonValue(data.value);
      setSpinButtonDisplayValue(formatter(data.value));
    } else if (data.displayValue !== undefined) {
      const newValue = parser(data.displayValue);
      if (!Number.isNaN(newValue)) {
        setSpinButtonValue(newValue);
        setSpinButtonDisplayValue(formatter(newValue));
      } else {
        // Display a "special" value when user types something
        // that's not parsable as a number.
        setSpinButtonValue(null);
        setSpinButtonDisplayValue("(null)");
      }
    }
  };

  //const [defaultVal, setDefault] = useState<number>(defaultValue)
  useEffect(() => {
    setSpinButtonValue(defaultValue);
    setSpinButtonDisplayValue(formatter(defaultValue));
  }, [defaultValue])

  const id = useId();
  const [spinButtonValue, setSpinButtonValue] = useState<number | null>(defaultValue);
  const [spinButtonDisplayValue, setSpinButtonDisplayValue] = useState(formatter(defaultValue));

  useEffect(() => {
    if (handleChange !== undefined) {
      if (spinButtonValue !== null)
        handleChange(spinButtonValue);
    }
  }, [spinButtonValue])

  return (
    <Field label={label} required={required}>
      <SpinButton
        {...field}
        value={spinButtonValue}
        displayValue={spinButtonDisplayValue}
        onChange={onSpinButtonChange}
        min={minValue}
        id={id}
        precision={2}
      />
    </Field>

  )
}