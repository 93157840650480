import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import ApprovalRequestsTab from "./ApprovalRequestsTab";
import Workflows from "./Workflows";
import useUser from "../Hooks/useUser";
import { UserContext } from "../Context/UserContext";
import { DeepLinkContext } from "../Context/DeepLinkContext";
import useAppContextDeepLinks from "../Hooks/useAppContextDeepLinks";
import {
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Spinner,
} from "@fluentui/react-components";
import { TeamsFxContext } from "./Context";
import { useContext } from "react";

export default function Routers() {
  const user = useUser();
  const deepLink = useAppContextDeepLinks();
  const teamsFx = useContext(TeamsFxContext);

  return (
    <UserContext.Provider value={user}>
      <DeepLinkContext.Provider value={deepLink}>
        {user.userInfo === undefined && user.error === undefined && (
          <Spinner style={{ margin: 100 }} />
        )}
        {user.error !== undefined && (
          <div
            className={
              teamsFx.themeString === "default"
                ? "light"
                : teamsFx.themeString === "dark"
                ? "dark"
                : "contrast"
            }
          >
            <div style={{ margin: "auto" }}>
              <MessageBar intent="error">
                <MessageBarBody>
                  <MessageBarTitle>Error</MessageBarTitle>
                  {(user.error as Error).message}
                </MessageBarBody>
              </MessageBar>
            </div>
          </div>
        )}
        {user.userInfo !== undefined && (
          <Router>
            <Routes>
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/termsofuse" element={<TermsOfUse />} />
              <Route path="/workflows/*" element={<Workflows />} />
              <Route
                path="/requests/:requestType/*"
                element={<ApprovalRequestsTab />}
              />
              <Route path="*" element={<Navigate to="/requests/received" />} />
            </Routes>
          </Router>
        )}
      </DeepLinkContext.Provider>
    </UserContext.Provider>
  );
}
