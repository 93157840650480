import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import {
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabValue,
  OverlayDrawer,
  DialogOpenChangeEvent,
  DialogOpenChangeData,
  DrawerHeader,
  DrawerHeaderTitle,
  Button,
  DrawerBody,
  Field,
  Input,
  Label,
  Textarea,
  MessageBar,
  MessageBarTitle,
  MessageBarBody,
  SpinButton,
  ProgressBar,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";

//Internal
import {
  BalanceTransfer,
  BalanceTransferSchema,
} from "../../../../Services/types/ContentHub/Credlt/BalanceTransfer";
import { DropDownData } from "../../../../Services/types/General/DropDownData";
import { ContentHubItemElement } from "../../../../Services/types/ContentHub/ContentHubItemElement";
import { TeamsFxContext } from "../../../Context";
import { FileUploaderCommon } from "../../../CommonComponents/FileUploader/FileUploader";
import { SelectDropDownData } from "../../../CommonComponents/SelectDropDownData/SelectDropDownData";
import { SelectCounterparty } from "../../../CommonComponents/SelectCounterparty/SelectCounterparty";
import { Counterparty } from "../../../../Services/types/General/Counterparty";
import { NumericInput } from "../../../CommonComponents/NumericInput/NumericInput";
import { ContentHubSiteElement } from "../../../../Services/types/ContentHub/ContentHubSiteElement";
import { ContentHubListsElement } from "../../../../Services/types/ContentHub/ContentHubListsElement";
import useContentHubForm from "../../../../Hooks/useContentHubForm";
import { UserService } from "../../../../Services/general/user";
import { UserContext } from "../../../../Context/UserContext";

//CSS
import "./BalanceTransfer.css";

const defaultForm: Record<string, string | any> = {
  'requestor': '',
  'counterpartyFrom': '',
  'accountNumberFrom': '',
  'transactionAmountFrom': 0,
  'counterpartyTo': '',
  'accountNumberTo': '',
  'transactionAmountTo': 0,
  'requestorComments': '',
  'status': 'Ready for Credit Approval',
  'clientApprovalAttached': '',
  'creditDocumentationAttached': '',
  'balanceTransferTemplateAttached': '',
  'finalCustomer': '',
  'billingAnalyst': null
}

export const FormBalanceTransfer = ({
  site,
  list,
  itemDefinition,
  drawerOpen,
  onDrawerClosed,
}: {
  site: ContentHubSiteElement | undefined;
  list: ContentHubListsElement | undefined;
  itemDefinition: ContentHubItemElement<BalanceTransfer> | null | undefined;
  drawerOpen: boolean;
  onDrawerClosed: (result: BalanceTransfer | null) => void;
}) => {
  //Ui
  const { userInfo } = useContext(UserContext);
  const [title, setTitle] = useState<string>("New Balance Transfer");
  const [editing, setEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>();

  //DropDownOptions
  const [yesNoOptions, setYesNoOptions] = useState<DropDownData[]>([]);

  //Drawer Methods
  const closeDrawer = (result: BalanceTransfer | null) => {
    // if (isDirty && !isSubmitSuccessful) {
    //     alert("Close Without Saving?");
    // }
    onDrawerClosed(result);
  };

  const onOpenChange = (
    _event: DialogOpenChangeEvent,
    data: DialogOpenChangeData
  ) => {
    if (data.open === false) {
      closeDrawer(null);
    }
  };

  useEffect(() => {
    if (drawerOpen === true) {
      if (userInfo != undefined) {
        let user = UserService.userProfileToContentHubUser(userInfo);
        if (user !== undefined) {
          setValue("requestor", user);
        }
      }
      //Load Options
      setYesNoOptions(getOptions("finalCustomer"));
      setItemForm(itemDefinition);
    } else {
      setEditing(false);
      reset(defaultForm);
      setSelectedTab("requestor");
      setReloadFiles(false);
      setSubmitStatus(null);
    }
  }, [drawerOpen]);

  //Form Methods
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    watch,
    formState: { isValid, errors, isSubmitSuccessful, isDirty, dirtyFields },
  } = useForm<BalanceTransfer>({
    mode: "all",
    resolver: yupResolver<BalanceTransfer>(BalanceTransferSchema),
  });

  const status = getValues("status");
  const step = getValues("_workflowStep");

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  //Form ContentHub Hook
  const {
    item,
    setItemForm,
    submitStatus,
    reloadFiles,
    setReloadFiles,
    beingSubmitted,
    setBeingSubmmitted,
    setSubmitStatus,
    submitHandler,
    getOptions,
  } = useContentHubForm<BalanceTransfer>({
    siteDefinition: site,
    listDefinition: list,
  });
  const correlationKey = site?.Id + "/" + list?.Id + "/" + item?.Id;
  //Load Item Selected
  useEffect(() => {
    loadSelected();
  }, [item]);

  const loadSelected = async () => {
    if (item !== null && item !== undefined) {
      setTitle("Editing Balance Transfer Request");
      setEditing(true);
      //Info Load
      const editItem: BalanceTransfer = {
        requestor: item.Fields.requestor.Value,
        counterpartyFrom: item.Fields.counterpartyFrom.Value,
        accountNumberFrom: item.Fields.accountNumberFrom.Value,
        transactionAmountFrom: item.Fields.transactionAmountFrom.Value,
        counterpartyTo: item.Fields.counterpartyTo.Value,
        accountNumberTo: item.Fields.accountNumberTo.Value,
        transactionAmountTo: item.Fields.transactionAmountTo.Value,
        requestorComments: item.Fields.requestorComments.Value,
        status: item.Fields.status.Value,
        clientApprovalAttached: item.Fields.clientApprovalAttached.Value,
        creditDocumentationAttached:
          item.Fields.creditDocumentationAttached.Value,
        balanceTransferTemplateAttached:
          item.Fields.balanceTransferTemplateAttached.Value,
        finalCustomer: item.Fields.finalCustomer.Value,
        billingAnalyst: item.Fields.billingAnalyst.Value,
        _attachments: item.Fields._attachments.Value,
        _workflowStep: item.Fields._workflowStep.Value,
      };

      reset(editItem);
      setReloadFiles(true);
    } else {
      setTitle("New Balance Transfer Request");
      reset(defaultForm);
      setReloadFiles(false);

      if (userInfo != undefined) {
        let user = UserService.userProfileToContentHubUser(userInfo);
        console.log(user);
        if (user !== undefined) {
          setValue("requestor", user);
        }
      }
    }
  };

  const submitForm: SubmitHandler<BalanceTransfer> = async (
    data: BalanceTransfer
  ) => {
    console.log(data);
    try {
      if (editing) {
        await submitHandler(data, item?.Id, editing, filesToUpload);
      } else {
        await submitHandler(data, null, editing, filesToUpload);
      }
    } catch (ex) {
      console.log("Failed Submit");
    }
  };

  //DropDowns Info Counterparties
  var defaultCounterpartyFrom: Counterparty = {
    Name:
      itemDefinition?.Fields.counterpartyFrom !== undefined
        ? itemDefinition?.Fields.counterpartyFrom.Value
        : "",
    Sic: "",
    CounterpartyClass: "",
    CounterpartyId: 0,
    Status: "ACTIVE",
  };

  var defaultCounterpartyTo: Counterparty = {
    Name:
      itemDefinition?.Fields.counterpartyTo !== undefined
        ? itemDefinition?.Fields.counterpartyTo.Value
        : "",
    Sic: "",
    CounterpartyClass: "",
    CounterpartyId: 0,
    Status: "ACTIVE",
  };
  const handleCounterparty = (counterparty: Counterparty, fromOrTo: string) => {
    switch (fromOrTo) {
      case "From":
        setValue("counterpartyFrom", counterparty.Name);
        break;

      case "To":
        setValue("counterpartyTo", counterparty.Name);
        break;
    }
  };

  //Handle Send To Billing
  const updateStatus = (status: string) => {
    setValue("status", status);

    if (status === "Ready for Billing Manager") {
      setValue(
        "billingAnalyst",
        UserService.userProfileToContentHubUser(userInfo!)!
      );
    }

    submitForm(getValues());
  };

  //Files
  const [filesToUpload, setFilesToUpload] = useState();
  const handleFiles = (files: any) => {
    setFilesToUpload(files);
    console.log(files);
  };

  //Tabs
  const [selectedTab, setSelectedTab] = useState<TabValue>("requestor");
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTab(data.value);
  };

  return (
    <div key="balanceTransferFormDiv">
      {
        <OverlayDrawer
          size="large"
          position="end"
          modalType="alert"
          open={drawerOpen}
          onOpenChange={onOpenChange}
        >
          {submitStatus !== null ? (
            submitStatus == true ? (
              <div className="submitMessage">
                <MessageBar key="info-attachments" intent="success">
                  <MessageBarBody>
                    <MessageBarTitle>Submit Success</MessageBarTitle>
                    Information submitted succesfully to database.
                  </MessageBarBody>
                </MessageBar>
              </div>
            ) : (
              <div className="submitMessage">
                <MessageBar key="info-attachments" intent="error">
                  <MessageBarBody>
                    <MessageBarTitle>Submit Error</MessageBarTitle>
                    Unexpected error when trying to submit information
                  </MessageBarBody>
                </MessageBar>
              </div>
            )
          ) : (
            <div></div>
          )}

          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular></Dismiss24Regular>}
                  onClick={() => {
                    closeDrawer(null);
                  }}
                ></Button>
              }
            >
              {title}
            </DrawerHeaderTitle>
          </DrawerHeader>
          <DrawerBody>
            {drawerOpen && !isLoading ? (
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="submitbutton">
                  {beingSubmitted === false ? (
                    <Button
                      // disabled={!isValid}
                      appearance="primary"
                      type="submit"
                    >
                      Save
                    </Button>
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <Field
                        validationMessage="Please Waiit... Saving and Uploading Files."
                        validationState="none"
                      >
                        <ProgressBar />
                      </Field>
                    </div>
                  )}
                </div>

                {editing && (
                  <div className="statusDiv">
                    <div className="status">
                      <h2>
                        <Label
                          htmlFor="statusId"
                          style={{ color: "" }}
                          className="statusLabel"
                        >
                          Status : {status}
                        </Label>
                      </h2>
                    </div>

                    {(step === "CreditApproved" && status === "Ready for Billing Analyst") && (
                      <Button
                        appearance="primary"
                        onClick={() =>
                          updateStatus("Ready for Billing Manager")
                        }
                      >
                        Submit to Billing Manager
                      </Button>
                    )}

                    {(step === "BillingManagerApproved" && status !== "Billing Manager Approved") && (
                      <Button
                        appearance="primary"
                        onClick={() => updateStatus("Completed")}
                      >
                        Complete Request
                      </Button>
                    )}
                  </div>
                )}

                <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                  <Tab id="requestor" value="requestor">
                    Requestor
                  </Tab>
                </TabList>
                {selectedTab == "requestor" && (
                  <div className="requestAreaTransfer">
                    <div className="requestorDiv">
                      <div className="errorMessage">
                        <ErrorMessage errors={errors} name="requestor" />
                      </div>
                      <Label>Requestor: {userInfo?.DisplayName}</Label>
                    </div>

                    {/* From  */}
                    <div className="counterpartyFromDiv">
                      <div className="errorMessage">
                        <ErrorMessage errors={errors} name="counterpartyFrom" />
                      </div>
                      <SelectCounterparty
                        label="Counterparty (From)"
                        showInfo={false}
                        defaultCounterparty={defaultCounterpartyFrom}
                        control={control}
                        name="counterpartyFrom"
                        handleCounterpartySelection={(data) =>
                          handleCounterparty(data, "From")
                        }
                      />
                    </div>

                    <div className="accountFromDiv">
                      <div className="accountFrom">
                        <div className="errorMessage">
                          <ErrorMessage
                            errors={errors}
                            name="accountNumberFrom"
                          />
                        </div>
                        <Field label="Account (From)">
                          <Controller
                            name="accountNumberFrom"
                            control={control}
                            render={({ field }) => (
                              <Input {...field} type="text" />
                            )}
                          />
                        </Field>
                      </div>
                    </div>

                    <div className="amountFromDiv">
                      <div className="amountFrom">
                        <div className="errorMessage">
                          <ErrorMessage
                            errors={errors}
                            name="transactionAmountFrom"
                          />
                        </div>
                        <NumericInput
                          label="Transaction Amount (From)"
                          control={control}
                          name="transactionAmountFrom"
                          isMoney={true}
                          defaultValue={item?.Fields.transactionAmountFrom.Value !== undefined ? item?.Fields.transactionAmountFrom.Value : 0}
                          handleChange={(data) => setValue("transactionAmountFrom", data)}
                        />
                      </div>
                    </div>

                    {/* To */}

                    <div className="counterpartyToDiv">
                      <div className="counterpartyTo">
                        <div className="errorMessage">
                          <ErrorMessage errors={errors} name="counterpartyTo" />
                        </div>
                        <SelectCounterparty
                          label="Counterparty (To)"
                          showInfo={false}
                          defaultCounterparty={defaultCounterpartyTo}
                          control={control}
                          name="counterpartyTo"
                          handleCounterpartySelection={(data) =>
                            handleCounterparty(data, "To")
                          }
                        />
                      </div>
                    </div>

                    <div className="accountToDiv">
                      <div className="accountTo">
                        <div className="errorMessage">
                          <ErrorMessage
                            errors={errors}
                            name="accountNumberTo"
                          />
                        </div>
                        <Field label="Account (To)">
                          <Controller
                            name="accountNumberTo"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                className="accountToInput"
                                key="accountTo"
                                type="text"
                              />
                            )}
                          />
                        </Field>
                      </div>
                    </div>

                    <div className="transactionAmountToDiv">
                      <div className="amountTo">
                        <div className="errorMessage">
                          <ErrorMessage
                            errors={errors}
                            name="transactionAmountTo"
                          />
                        </div>
                        <NumericInput
                          label="Transaction Amount (To)"
                          control={control}
                          name="transactionAmountTo"
                          isMoney={true}
                          defaultValue={item?.Fields.transactionAmountTo.Value !== undefined ? item?.Fields.transactionAmountTo.Value : 0}
                          handleChange={(data) => setValue("transactionAmountTo", data)}
                        />
                      </div>
                    </div>

                    <div className="requestorCommentsDiv">
                      <div className="requestorComment">
                        <div className="errorMessage">
                          <ErrorMessage
                            errors={errors}
                            name="requestorComments"
                          />
                        </div>
                        <Field label="Requestor Comments">
                          <Controller
                            name="requestorComments"
                            control={control}
                            render={({ field }) => (
                              <Textarea
                                {...field}
                                className="requestorCommentsInput"
                                key="requestorComments"
                              />
                            )}
                          />
                        </Field>
                      </div>
                    </div>

                    {/* Other */}

                    <div className="clientApprovalDiv">
                      <div className="billingManagerReview">
                        <div className="errorMessage">
                          <ErrorMessage
                            errors={errors}
                            name="clientApprovalAttached"
                          />
                        </div>
                        <SelectDropDownData
                          label="Client Approval Attached:"
                          options={yesNoOptions}
                          control={control}
                          name="clientApprovalAttached"
                        />
                      </div>
                    </div>

                    <div className="creditDocumentDiv">
                      <div className="creditDocumentation">
                        <div className="errorMessage">
                          <ErrorMessage
                            errors={errors}
                            name="creditDocumentationAttached"
                          />
                        </div>
                        <SelectDropDownData
                          label="Credit Documentation Attached:"
                          options={yesNoOptions}
                          control={control}
                          name="creditDocumentationAttached"
                        />
                      </div>
                    </div>

                    <div className="templateDiv">
                      <div className="balanceTransfer">
                        <div className="errorMessage">
                          <ErrorMessage
                            errors={errors}
                            name="balanceTransferTemplateAttached"
                          />
                        </div>
                        <SelectDropDownData
                          label="Balance Transfer Templete Attached:"
                          options={yesNoOptions}
                          control={control}
                          name="balanceTransferTemplateAttached"
                        />
                      </div>
                    </div>

                    <div className="finalCustomerDiv">
                      <div className="finalCustomer">
                        <div className="errorMessage">
                          <ErrorMessage errors={errors} name="finalCustomer" />
                        </div>
                        <SelectDropDownData
                          label="Final Customer:"
                          options={yesNoOptions}
                          control={control}
                          name="finalCustomer"
                        />
                      </div>
                    </div>

                    <div className="attachmentsDiv">
                      <h2>Attachments</h2>
                      <FileUploaderCommon
                        reload={reloadFiles}
                        attachment={reloadFiles ? item?.Fields._attachments.Value! : itemDefinition?.Fields._attachments.Value!}
                        listName="BalanceTransfer"
                        filesOnForm={handleFiles}
                        fileTypes={['pdf', 'txt', 'xlsx', 'xls']}
                      />
                    </div>
                  </div>
                )}
              </form>
            ) : (
              <div></div>
            )}
          </DrawerBody>
        </OverlayDrawer>
      }
    </div>
  );
};
