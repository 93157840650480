import { ItemChangeSet } from "../../../Services/types/ContentHub/ContentHubItemElement";
import {
  OverlayDrawer,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerBody,
  Button,
  Divider,
  Caption1,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Caption2,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useContext, useEffect, useState } from "react";
import { ContentHubListsElement } from "../../../Services/types/ContentHub/ContentHubListsElement";
import { formatDate } from "../../../utils/utils";
import { ContentHubUser } from "../../../Services/types/General/user";

import "./ContentHubItemHistory.css";
import { ContentHubAttachments } from "../../../Services/types/ContentHub/ContentHubAttachments";
import { UserService } from "../../../Services/general/user";
import { TeamsFxContext } from "../../Context";

export const ContentHubItemHistory = ({
  listDefinition,
  itemChanges,
  historyOpen,
  onClosedDrawer,
}: {
  listDefinition: ContentHubListsElement;
  itemChanges: ItemChangeSet[];
  historyOpen: boolean;
  onClosedDrawer: () => void;
}) => {
  const [userMap, setUserMap] = useState<{ [key: string]: ContentHubUser }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { teamsUserCredential } = useContext(TeamsFxContext);
  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      const users: { [key: string]: ContentHubUser } = {};

      // Fetch user details for each item change
      for (const change of itemChanges) {
        if (change.UserId && !users[change.UserId]) {
          const user = await UserService.getUserById(
            change.UserId,
            teamsUserCredential!
          );
          users[change.UserId] = user;
        }
      }
      setUserMap(users);
      setLoading(false);
    };

    if (itemChanges.length > 0) {
      fetchUserDetails();
    }
  }, [itemChanges]);

  const closeDrawer = () => {
    onClosedDrawer();
  };

  return (
    <OverlayDrawer
      size="large"
      position="start"
      modalType="modal"
      open={historyOpen}
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular></Dismiss24Regular>}
              onClick={() => {
                closeDrawer();
              }}
            ></Button>
          }
        >
          Item History
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody>
        <Accordion multiple>
          {itemChanges.map((changes: ItemChangeSet) => {
            const user = userMap[changes.UserId]; // Retrieve user details from the map
            return (
              <AccordionItem className="accordionItem" value={changes.Version}>
                <AccordionHeader>
                  <div>
                    <div>Version: {changes.Version}</div>
                    <div>
                      <Caption1>
                        Date: {formatDate(changes.Timestamp)}{" "}
                      </Caption1>
                    </div>
                    <div>
                      Modified By:{" "}
                      {loading ? (
                        <Skeleton>
                          <SkeletonItem size={16} />
                        </Skeleton>
                      ) : (
                        user?.Name || changes.UserId
                      )}
                    </div>
                  </div>
                </AccordionHeader>
                <AccordionPanel>
                  <div className="accordionPanel">
                    {changes.Changes.map((fieldChange) => {
                      const field = listDefinition.Fields.find(
                        (x) => x.Id === fieldChange.FieldId
                      );

                      switch (fieldChange.Type) {
                        case "string":
                          {
                            return (
                              <div className="fieldValue">
                                <b>{field?.DisplayName}</b>: {fieldChange.Value}
                              </div>
                            );
                          }
                          break;

                        case "datetime": {
                          return (
                            <div className="fieldValue">
                              <b>{field?.DisplayName}</b>:{" "}
                              {formatDate(fieldChange.Value)}
                            </div>
                          );
                        }

                        case "person":
                          {
                            const person = fieldChange.Value as ContentHubUser;
                            if (person !== null && person !== undefined) {
                              return (
                                <div className="fieldValue">
                                  <b>{field?.DisplayName}</b>: {person.Name}
                                </div>
                              );
                            }
                          }
                          break;


                        case "person[]": {
                          return (<div></div>)
                        }

                        case "attachments": {
                          const attach =
                            fieldChange.Value as ContentHubAttachments;
                          return (
                            <div>
                              <div>
                                <b>
                                  Attachments Added:{" "}
                                  {attach != null && attach.Filenames != null && attach.Filenames.length > 0
                                    ? attach.Filenames[0].toString()
                                    : "None"}
                                </b>
                              </div>
                              <div></div>
                            </div>
                          );
                        }

                        default: {
                          return (
                            <div className="fieldValue">
                              <b>{field?.DisplayName}</b>: {fieldChange.Value}
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </DrawerBody>
    </OverlayDrawer>
  );
};
