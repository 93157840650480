
export type DropDownData = {
    label: string,
    value: string
}

export const dropDownToString = (value: string | DropDownData) => {
    if (value !== null && value !== undefined) {
        if (typeof value === "string") {
            return value;
        }
        const currentValue: DropDownData = value;
        return currentValue.label
    }
    return "";
}

export const arrayToDropDown = (array: any[]) => {
    let newDropDownData: DropDownData[] = [];
    array.map((variable) => {
        let dropData: DropDownData = {
            label: variable,
            value: variable
        }
        newDropDownData.push(dropData)
    })
    return newDropDownData;
}