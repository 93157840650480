import { object, string, ObjectSchema, array } from 'yup';

export interface ContentHubAttachments {
    ItemFolderId?: string | null,
    Filenames?: string[] | null
}

export const ContentHubAttachmentsSchema: ObjectSchema<ContentHubAttachments> = object({    
    ItemFolderId: string().nullable(),
    Filenames: array().of(string().defined()).nullable()
})