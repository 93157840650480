import config from "../../components/sample/lib/config";
import {
  BearerTokenAuthProvider,
  createApiClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import { ContentHubUser, User } from "../types/General/user";
import cache from "react";

const apiBaseUrl = config.apiEndpoint + "/api/";

async function getToken(credential: TeamsUserCredential) {
  return (await credential.getToken(""))!.token;
}

function getApiClient(credential: TeamsUserCredential) {
  const apiClient = createApiClient(
    apiBaseUrl,
    new BearerTokenAuthProvider(() => getToken(credential))
  );
  return apiClient;
}

export namespace UserService {
  export async function getUserById(
    userId: string,
    teamsUserCredential: TeamsUserCredential
  ) {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(`user/id/${userId}`);
    return response.data as ContentHubUser;
  }

  export async function getUser(
    userSearch: string,
    teamsUserCredential: TeamsUserCredential
  ) {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(`user/${userSearch}`);
    return response.data as ContentHubUser[];
  }

  export async function getUserInGroup(
    userSearch: string,
    groupName: string,
    teamsUserCredential: TeamsUserCredential
  ) {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(
      `group/${groupName}/member/${userSearch}`
    );
    return response.data as ContentHubUser[];
  }
  export const userProfileToContentHubUser = (user: User) => {
    if (user !== undefined) {
      const contentHubUser: ContentHubUser = {
        Id: user.Id,
        Name: user.DisplayName,
        JobTitle: user.JobTitle,
        Email: user.Mail,
        UserPrincipalName: user.UserPrincipalName,
        Department: user.Department,
      };
      return contentHubUser;
    }
  };
}
