import { ContentHubItemElement } from "../../../../Services/types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../../../Services/types/ContentHub/ContentHubListsElement";
import { ContentHubSiteElement } from "../../../../Services/types/ContentHub/ContentHubSiteElement";
import { BalanceTransfer } from "../../../../Services/types/ContentHub/Credlt/BalanceTransfer";
import { ContentHubUser } from "../../../../Services/types/General/user";
import { ContentHubListRegistry } from "../../SitesRegistry";
import { FormBalanceTransfer } from "./BalanceTransferForm";

export const BalanceTransferListRegistry: ContentHubListRegistry = {
  name: "BalanceTransfer",
  header: "Balance Transfer",
  columns: [
    { key: "id", displayName: "ID", visible: false },
    { key: "counterpartyFrom", displayName: "Counterparty (From)" },
    { key: "accountNumberFrom", displayName: "Account Number (From)" },
    { key: "counterpartyTo", displayName: "Counterparty (To)" },
    { key: "accountNumberTo", displayName: "Account Number (To)" },
    { key: "status", displayName: "Status" },
    { key: "requestor", displayName: "Requestor" },
  ],
  mapper: function (
    item: ContentHubItemElement<BalanceTransfer>
  ): Record<string, any> {
    var requestor = item.Fields.requestor.Value as ContentHubUser;
    return {
      id: item.Id,
      counterpartyFrom: item.Fields.counterpartyFrom.Value,
      accountNumberFrom: item.Fields.accountNumberFrom.Value,
      transactionAmountFrom: item.Fields.transactionAmountFrom.Value,
      counterpartyTo: item.Fields.counterpartyTo.Value,
      accountNumberTo: item.Fields.accountNumberTo.Value,
      transactionAmountTo: item.Fields.transactionAmountTo.Value,
      status: item.Fields.status.Value,
      requestor: requestor.Name,
    };
  },
  renderForm: function (
    siteDefinition: ContentHubSiteElement,
    listDefinition: ContentHubListsElement,
    selectedItem: ContentHubItemElement<BalanceTransfer>,
    drawerOpen: boolean,
    onDrawerClosed: (result: BalanceTransfer | null) => void
  ): JSX.Element {
    return (
      <FormBalanceTransfer
        site={siteDefinition}
        list={listDefinition}
        itemDefinition={selectedItem}
        drawerOpen={drawerOpen!}
        onDrawerClosed={onDrawerClosed}
      />
    );
  },
};
