import { ContentHubSiteElement } from "../types/ContentHub/ContentHubSiteElement";
import { ContentHubItemElement } from "../types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../types/ContentHub/ContentHubListsElement";
import { ContentHubRequest } from "../types/ContentHub/ContentHubRequest";
import config from "../../components/sample/lib/config";
import { BearerTokenAuthProvider, createApiClient, TeamsUserCredential } from "@microsoft/teamsfx";
import { ContentHubResponse } from "../types/ContentHub/ContentHubResponse";
import { SharePointDriveService } from "../sharepoint/sharePointDrive";
import { ContentHubAttachments, ContentHubAttachmentsSchema } from "../types/ContentHub/ContentHubAttachments";

const apiBaseUrl = config.apiEndpoint + "/api/";

async function getToken(credential: TeamsUserCredential) {
    return (await credential.getToken(""))!.token;
}

function getApiClient(credential: TeamsUserCredential) {
    const apiClient = createApiClient(
        apiBaseUrl,
        new BearerTokenAuthProvider(() => getToken(credential))
    )
    return apiClient;
}

export namespace ContentHubService {
    export async function createSiteList(teamsUserCredential: TeamsUserCredential) {
        const apiClient = getApiClient(teamsUserCredential);

        const response = await apiClient.post("createSiteListTest");
        return response.data;
    }

    export async function getSite(siteName: string, teamsUserCredential: TeamsUserCredential) {
        const apiClient = getApiClient(teamsUserCredential);

        const response = await apiClient.get(`sites/${siteName}`);
        return response.data as ContentHubSiteElement;
    }

    export async function getList(siteId: string, listName: string, teamsUserCredential: TeamsUserCredential) {
        const apiClient = getApiClient(teamsUserCredential);

        const response = await apiClient.get(`sites/${siteId}/lists/${listName}`);
        return response.data as ContentHubListsElement;
    }

    export async function getItems(request: ContentHubRequest, teamsUserCredential: TeamsUserCredential) {
        const apiClient = getApiClient(teamsUserCredential);

        const response = await apiClient.post('getListItems', request);
        return response.data as ContentHubResponse;
    }

    export async function getItemVersions<T>(request: ContentHubRequest, teamsUserCredential: TeamsUserCredential) {
        const apiClient = getApiClient(teamsUserCredential);

        const response = await apiClient.post('getItemVersion', request);
        let item = await response.data as ContentHubItemElement<T>;

        return item.Changesets;
    }

    export async function createItem<T>(request: ContentHubRequest, teamsUserCredential: TeamsUserCredential, files?: File[]) {
        const apiClient = getApiClient(teamsUserCredential);

        if (files !== undefined && files !== null) {
            const attachmentValue: ContentHubAttachments = {
                ItemFolderId: 'UPLOADING FILES',
                Filenames: []
            }

            request = {
                ...request,
                Fields: {
                    ...request.Fields,
                    "_attachments": attachmentValue
                }
            }
        }

        const response = await apiClient.post('createItem', request, {
        });
        const itemCreated = response.data as ContentHubItemElement<T>;
        request.ItemId = itemCreated.Id

        //If there were attachments added
        if (files !== undefined && files !== null) {
            let folderId = await uploadDocuments(request, files!, teamsUserCredential);
            const fileName: string[] = files!.map((file: File) => file.name);

            if (folderId !== null && folderId !== undefined) {
                folderId = folderId.replaceAll('"', '');

                const attachmentValue: ContentHubAttachments = {
                    ItemFolderId: folderId.toString(),
                    Filenames: fileName?.length === 0 ? [] : fileName
                }

                const updateRequest: ContentHubRequest = {
                    ...request,
                    Fields: {
                        "_attachments": attachmentValue
                    }
                }
                var itemWithAttachments = await updateItems(updateRequest, teamsUserCredential);
                return itemWithAttachments;
            }
        }

        return itemCreated;
    }

    export async function updateItems(request: ContentHubRequest, teamsUserCredential: TeamsUserCredential, files?: File[]) {
        const apiClient = getApiClient(teamsUserCredential);

        //If there are attachments
        const attachements = request.Fields!["_attachments"] as ContentHubAttachments;
        if (attachements !== undefined && attachements !== null) {
            let folderId = await uploadDocuments(request, files!, teamsUserCredential);

            const attachDriveId = attachements.ItemFolderId;

            //Update the attachment drive ID if there was none
            if (files !== null && files !== undefined && files.length > 0) {
                if (attachDriveId !== undefined && attachDriveId !== null) {
                    const driveId = attachDriveId.toString();
                    const fileName: string[] = files!.map((file: File) => file.name);

                    const attachmentValue: ContentHubAttachments = {
                        ItemFolderId: driveId,
                        Filenames: fileName?.length === 0 ? [] : fileName
                    }

                    if (folderId !== null && folderId !== undefined) {
                        request.Fields = {
                            ...request.Fields,
                            "_attachments": attachmentValue
                        };
                    }

                }
            }
        }

        const response = await apiClient.post("updateItem", request);
        return response.data;
    }

    async function uploadDocuments(request: ContentHubRequest, files: File[], teamsUserCredential: TeamsUserCredential) {
        let folderId = await SharePointDriveService.uploadFiles(request.ItemId!, request.ListName!, files, teamsUserCredential);
        return folderId;
    }
}