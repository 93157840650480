import { useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "./Context";
import { UserContext } from "../Context/UserContext";
import {
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  SelectTabData,
  SelectTabEvent,
  Spinner,
  Tab,
  TabList,
  TabValue,
} from "@fluentui/react-components";
import { ApprovalsComponent } from "./ApprovalTab/Approvals/Approvals";
import { DeepLinkContext } from "../Context/DeepLinkContext";
import { matchPath } from "react-router-dom";

//Get Info From MS Graph Function App

export default function ApprovalRequestsTab() {
  const { themeString } = useContext(TeamsFxContext);
  const user = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState<TabValue>("received");
  const [selectedItemId, setSelectedItemId] = useState<string>("");
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTab(data.value);
  };

  const [deepLink, setDeepLink] = useContext(DeepLinkContext);

  useEffect(() => {
    let link = deepLink;
    if (link == null) return;
    setDeepLink(null);

    let locationMatch = matchPath(
      "/requests/:selectedTab/:requestId?",
      deepLink
    );
    let selectedTabName = locationMatch?.params["selectedTab"];
    let requestId = locationMatch?.params["requestId"];
    setSelectedTab(selectedTabName);
    if (requestId) {
      setSelectedItemId(requestId);
    }
  }, [deepLink, setDeepLink]);

  const clearSelectedItemId = () => setSelectedItemId("");
  return (
    <div
      className={
        themeString === "default"
          ? "light"
          : themeString === "dark"
          ? "dark"
          : "contrast"
      }
    >
      <div className="welcome page flexfill nooverflow">
        <div className="narrow page-padding flexfill nooverflow">
            <>
              <h1 className="Left welcomeHeading">
                Welcome, {user.userInfo!.DisplayName}!
              </h1>
              <div className="flexfill nooverflow">
                <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                  <Tab id="Approvals" value="received">
                    Approvals
                  </Tab>
                  <Tab id="Requests" value="sent">
                    Requests
                  </Tab>
                </TabList>
                <div
                  style={{ marginTop: "15px" }}
                  className="flexfill nooverflow"
                >
                  <div
                    style={{
                      display: selectedTab === "received" ? "flex" : "none",
                    }}
                    className="flexfill nooverflow"
                  >
                    <ApprovalsComponent
                      email={user.userInfo!.Mail}
                      listType={"Approvers"}
                      selectedId={
                        selectedTab === "received" ? selectedItemId : ""
                      }
                      onDrawerClosed={clearSelectedItemId}
                    />
                  </div>
                  <div
                    style={{
                      display: selectedTab === "sent" ? "flex" : "none",
                    }}
                    className="flexfill nooverflow"
                  >
                    <ApprovalsComponent
                      email={user.userInfo!.Mail}
                      listType={"Requestor"}
                      selectedId={selectedTab === "sent" ? selectedItemId : ""}
                      onDrawerClosed={clearSelectedItemId}
                    />
                  </div>
                </div>
              </div>
            </>
        </div>
      </div>
    </div>
  );
}
