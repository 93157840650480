import { Input, Field } from "@fluentui/react-components";
import { MoreHorizontal24Filled } from "@fluentui/react-icons";
import { useState, useContext, useCallback, useEffect } from "react";
import "../ContentHubTable/CustomTheme.scss";
import { TeamsFxContext } from "../../Context";
import { useDebouncedCallback } from "use-debounce";
import {
  Menu,
  MenuTrigger,
  ToolbarButton,
  MenuPopover,
  MenuList,
  MenuItem,
  MenuProps,
} from "@fluentui/react-components";
//New Table
import { Table, useTable, PagingPosition } from "ka-table";
import { kaPropsUtils } from "ka-table/utils";
import { Column } from "ka-table/models";
import { ICellTextProps } from "ka-table/props";
import { ContentHubService } from "../../../Services/contentHub/contentHub";
import { ContentHubRequest } from "../../../Services/types/ContentHub/ContentHubRequest";
import { ItemChangeSet } from "../../../Services/types/ContentHub/ContentHubItemElement";
import { ContentHubItemHistory } from "../ContentHubItemHistory/ContentHubItemHistory";
import { ContentHubListsElement } from "../../../Services/types/ContentHub/ContentHubListsElement";

export const ContentHubTable = ({
  onItemClicked,
  onNext,
  searchTrigger,
  hasMoreItems,
  isLoading,
  showEndMessage,
  list,
  dataItems,
  tableColumns,
}: {
  onItemClicked: (item: any) => void;
  onNext: () => void;
  searchTrigger?: (value: string) => void;
  hasMoreItems: boolean;
  isLoading: boolean;
  showEndMessage: boolean;
  list?: ContentHubListsElement;
  dataItems: any[];
  tableColumns: Column[];
}) => {
  const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
  const [selectedData, changeSelectedData] = useState<any>();

  const table = useTable({
    onDispatch: (action, tableProps) => {
      const selected = kaPropsUtils.getSelectedData(tableProps).pop();
      if (selected !== undefined) {
        changeSelectedData(selected);
        onItemClicked(selected);
      }
    },
  });

  const [itemHistoryOpen, setItemHistoryOpen] = useState(false);
  const [itemHistoryData, setItemHistoryData] = useState<ItemChangeSet[]>([]);
  const onCloseHistory = () => {
    setItemHistoryOpen(false);
    setItemHistoryData([]);
  };

  const getItemVersion = async (itemId: string) => {
    if (list !== null && list !== undefined) {
      const sisteId = list.SiteId;
      const listId = list.Id;

      if (
        sisteId !== null &&
        sisteId !== undefined &&
        listId !== null &&
        listId !== undefined
      ) {
        var request: ContentHubRequest = {
          SiteId: sisteId,
          ListId: listId,
          ItemId: itemId,
        };

        const data = (await ContentHubService.getItemVersions(
          request,
          teamsUserCredential!
        )) as ItemChangeSet[];

        setItemHistoryOpen(true);
        setItemHistoryData(data);
      }
    }
  };

  //Search
  const searchCallback = useCallback(
    useDebouncedCallback((inputValue) => {
      if (inputValue !== undefined) {
        searchTrigger!(inputValue);
      }
    }, 1000),
    [searchTrigger]
  );

  const [openRowId, setOpenRowId] = useState<string | null>(null);
  const MenuForTable = ({ rowKeyValue }: ICellTextProps) => {
    const isOpen = openRowId === rowKeyValue;

    const onOpenChange: MenuProps["onOpenChange"] = (e, data) => {
      e.stopPropagation();
      setOpenRowId(data.open ? rowKeyValue : null);
    };

    return (
      <Menu open={isOpen} onOpenChange={onOpenChange}>
        <MenuTrigger>
          <ToolbarButton aria-label="More" icon={<MoreHorizontal24Filled />} />
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItem onClick={() => getItemVersion(rowKeyValue)}>
              Item History
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  };

  useEffect(() => {
    table.changeProps({ columns: tableColumns, rowKeyField: "id" });
  }, [tableColumns]);
  return (
    <div className="flexfill nooverflow">
      <div className="searchInputDiv">
        <Field label="Search">
          <Input
            onChange={(event) => {
              searchCallback(event.target.value);
            }}
          />
        </Field>
      </div>
      {/* New Table */}
      <div
        className={
          "contenthubtable flexfill nooverflow " +
          (themeString === "dark" ? "custom-theme-dark" : "custom-theme-light")
        }
      >
        <Table
          table={table}
          columns={tableColumns}
          data={dataItems}
          paging={{
            enabled: true,
            pageIndex: 0,
            pageSize: 15,
            pageSizes: [5, 10, 15],
            position: PagingPosition.Bottom,
          }}
          rowKeyField={"id"}
          childComponents={{
            dataRow: {
              elementAttributes: () => ({
                onClick: (event, extendedEvent) => {
                  if (extendedEvent.childProps.rowKeyField !== "menuClick") {
                    table.selectSingleRow(extendedEvent.childProps.rowKeyValue);
                  }
                },
              }),
            },
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case "menuClick":
                    return <MenuForTable {...props} />;
                }
              },
            },
          }}
          noData={{
            text: "No Data Found",
          }}
          loading={{
            enabled: isLoading,
            text: "Loading...",
          }}
        />
      </div>
      <div>
        <ContentHubItemHistory
          listDefinition={list!}
          itemChanges={itemHistoryData}
          historyOpen={itemHistoryOpen}
          onClosedDrawer={onCloseHistory}
        />
      </div>
    </div>
  );
};
