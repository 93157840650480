import { Counterparty } from "../types/General/Counterparty";
import config from "../../components/sample/lib/config";
import { BearerTokenAuthProvider, createApiClient, TeamsUserCredential } from "@microsoft/teamsfx";

const apiBaseUrl = config.apiEndpoint + "/api/";

async function getToken(credential: TeamsUserCredential) {
    return (await credential.getToken(""))!.token;
}

function getApiClient(credential: TeamsUserCredential) {
    const apiClient = createApiClient(
        apiBaseUrl,
        new BearerTokenAuthProvider(() => getToken(credential))
    )
    return apiClient;
}

export namespace CounterpartyService {
    export async function getCounterparties(teamsUserCredential: TeamsUserCredential){
        const apiClient = getApiClient(teamsUserCredential);

        const response = await apiClient.get("getCounterparties");
        return response.data as Counterparty[];
    }
}