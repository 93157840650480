import { useState, useContext, useEffect } from "react";
import { ContentHubService } from "../Services/contentHub/contentHub";
import { ContentHubRequest } from "../Services/types/ContentHub/ContentHubRequest";
import { TeamsFxContext } from "../components/Context";
import { ContentHubSiteElement } from "../Services/types/ContentHub/ContentHubSiteElement";
import { ContentHubListsElement } from "../Services/types/ContentHub/ContentHubListsElement";
import { DropDownData } from "../Services/types/General/DropDownData";
import { ContentHubItemElement } from "../Services/types/ContentHub/ContentHubItemElement";

const useContentHubForm = <T,>({
    siteDefinition,
    listDefinition,
    itemDefinition

}: {
    siteDefinition: ContentHubSiteElement | undefined,
    listDefinition: ContentHubListsElement | undefined,
    itemDefinition?: ContentHubItemElement<T> | null | undefined
}) => {
    const { teamsUserCredential } = useContext(TeamsFxContext);

    //Item
    const [item, setItemForm] = useState<ContentHubItemElement<T> | null | undefined>();

    //Files
    const [reloadFiles, setReloadFiles] = useState<boolean>(false);

    const [submitStatus, setSubmitStatus] = useState<boolean | null>(null);
    const [beingSubmitted, setBeingSubmmitted] = useState<boolean>(false);
    const submitHandler = async (data: any, id: string | null | undefined, edit: boolean, files?: any | null | undefined) => {
        try {
            if (!edit) {
                await createItem(data, files);

            }
            else {
                if (id !== null && id !== undefined) {
                    await updateItem(data, id, files)
                }
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }

    //Create Item
    const createItem = async (data: any, files?: any | null | undefined) => {
        try {
            setBeingSubmmitted(true);
            if (siteDefinition !== undefined && listDefinition !== undefined) {
                var contentHubRequest: ContentHubRequest = {
                    SiteId: siteDefinition.Id,
                    ListName: listDefinition.Name,
                    ListId: listDefinition.Id,
                    Fields: data
                };

                var createResponse = await ContentHubService.createItem(contentHubRequest, teamsUserCredential!, files) as ContentHubItemElement<T>;
                setSubmitStatus(true);
                setBeingSubmmitted(false);
                //setReloadFiles(true);
                setItemForm(createResponse);
            }
        }
        catch (ex) {
            console.log(ex);
            setSubmitStatus(false);
            setBeingSubmmitted(false);
        }
    }

    //Update Item
    const updateItem = async (data: any, id: string, files?: any | null | undefined) => {
        try {
            setBeingSubmmitted(true);
            if (siteDefinition !== undefined && listDefinition !== undefined) {
                var contentHubRequest: ContentHubRequest = {
                    SiteId: siteDefinition.Id,
                    ListName: listDefinition.Name,
                    ListId: listDefinition.Id,
                    ItemId: id,
                    Fields: data
                };

                var updateResponse = await ContentHubService.updateItems(contentHubRequest, teamsUserCredential!, files) as ContentHubItemElement<T>;
                setSubmitStatus(true);
                setItemForm(updateResponse);
            }
        }
        catch (ex) {
            console.log(ex);
            setSubmitStatus(false);
            setBeingSubmmitted(false);
        }
        finally {
            //await loadItems();
            //setReloadFiles(true);
            setBeingSubmmitted(false);
        }
    }

    //Set Option To DropDown
    const getOptions = (columnName: string) => {

        if (columnName !== '') {
            const columnInList = listDefinition?.Fields.find((field) => {
                return field.Name === columnName;
            })

            if (columnInList?.Type === "choice") {
                let newDropDown: DropDownData[] = [];

                columnInList.Options?.forEach((value) => {
                    const info: DropDownData = {
                        label: value,
                        value: value
                    }
                    newDropDown.push(info);
                })

                return newDropDown;
            }
        }

        let emtpy: DropDownData[] = [];
        return emtpy;
    }

    return { item, submitStatus, reloadFiles, setReloadFiles, beingSubmitted, setBeingSubmmitted, setSubmitStatus, setItemForm, submitHandler, getOptions }
}

export default useContentHubForm;