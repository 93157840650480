import { app } from "@microsoft/teams-js";
import { useState, useEffect } from "react";

export default function useAppContextDeepLinks() {
  const [deepLink, setDeepLink] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      let context = await app.getContext();
      let urlPayload = context.page.subPageId;
      if (urlPayload !== undefined && urlPayload !== "") {
        try {
          let payload = JSON.parse(JSON.stringify(urlPayload));
          setDeepLink(payload);
        } catch (ex) {
          console.error(ex);
        }
      }
    })();
  }, []);
  return [deepLink, setDeepLink];
}
