import { Field } from "@fluentui/react-components";
import { Control, FieldValues, useController, Path } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { getSelectStyle, getSelectTheme } from "../CommonSelect/CommonSelect";
import { useContext, useCallback, useState } from "react";
import { TeamsFxContext } from "../../Context";
import { useDebouncedCallback } from "use-debounce";
import { UserService } from "../../../Services/general/user";

export const SelectPerson = <T extends FieldValues>({
  label,
  group,
  control,
  name,
  multiple,
  required,
}: {
  label: string;
  group?: string | undefined;
  control: Control<T>;
  name: Path<T>;
  multiple?: boolean;
  required?: boolean;
}) => {
  const [requiredField, setRequiredField] = useState(required);
  const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
  const { field } = useController({
    control,
    name,
  });

  const searchCallback = useCallback(
    useDebouncedCallback((inputValue, callback) => {
      if (group !== undefined) {
        UserService.getUserInGroup(
          inputValue,
          group,
          teamsUserCredential!
        ).then((options) => callback(options));
      } else {
        UserService.getUser(inputValue, teamsUserCredential!).then((options) =>
          callback(options)
        );
      }
    }, 1000),
    []
  );

  const onBlur = () => {
    if (field.value !== undefined || field.value) {
      setRequiredField(false);
    } else {
      setRequiredField(true);
    }
  };

  return (
    <Field
      label={label}
      required={requiredField !== undefined ? requiredField : false}
    >
      <AsyncSelect
        {...field}
        cacheOptions={true}
        styles={getSelectStyle(themeString)}
        theme={getSelectTheme(themeString)}
        loadOptions={searchCallback}
        getOptionValue={(option) => `${option.Name}`}
        getOptionLabel={(option) => `${option.Name}`}
        isMulti={multiple}
        isClearable={true}
        noOptionsMessage={() => "Search for a User"}
        //onBlur={onBlur}
      />
    </Field>
  );
};
