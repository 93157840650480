import { useCallback, useContext, useEffect, useState } from "react";
import { ContentHubRequest } from "../Services/types/ContentHub/ContentHubRequest";
import { ContentHubListsElement } from "../Services/types/ContentHub/ContentHubListsElement";
import { ContentHubService } from "../Services/contentHub/contentHub";
import { TeamsFxContext } from "../components/Context";

export default function useContentHub(siteId: string, listName: string) {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  useEffect(() => {
    (async () => {
      if (siteId !== undefined && listName !== undefined) {
        setContinuationString(undefined);
        setShouldLoadItems(false);
        setIsLoading(false);
        setShouldRepopulate(true);
      }
    })();
  }, [siteId, listName]);

  const [shouldRepopulate, setShouldRepopulate] = useState<boolean>(true);
  useEffect(() => {
    if (shouldRepopulate) {
      (async () => {
        var listData = await ContentHubService.getList(
          siteId,
          listName,
          teamsUserCredential!
        );
        setShouldRepopulate(false);
        setListDefinition(listData);
      })();
    }
  }, [listName, shouldRepopulate, siteId, teamsUserCredential]);

  const [continuationString, setContinuationString] = useState<
    string | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [listDefinition, setListDefinition] =
    useState<ContentHubListsElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldLoadItems, setShouldLoadItems] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    setItems([]);
    //await ContentHubService.createSiteList(teamsUserCredential!);
  }, [listDefinition]);

  useEffect(() => {
    if (shouldLoadItems && listDefinition !== null && !isLoading) {
      setIsLoading(true);
      (async () => {
        var contentHubRequest: ContentHubRequest = {
          SiteId: listDefinition.SiteId,
          ListId: listDefinition.Id,
          MaxItems: 50,
          ContinuationString: continuationString,
        };

        if (searchTerm !== null) {
          contentHubRequest.Filter = searchTerm;
        }

        var itemsResponse = await ContentHubService.getItems(
          contentHubRequest,
          teamsUserCredential!
        );
        setCurrentPage((c) => c + 1);
        setContinuationString(itemsResponse.ContinuationString ?? undefined);
        setItems((i) => i.concat(itemsResponse.Items).reverse());
        setIsLoading(false);
        setShouldLoadItems(false);
      })();
    }
  }, [isLoading, shouldLoadItems, listDefinition]);

  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const LoadMoreItems = useCallback(() => {
    setShouldLoadItems(true);
  }, []);

  const ClearItems = useCallback(() => {
    setShouldRepopulate(true);
  }, []);

  const SearchItems = useCallback((searchTerm: string) => {
    if ((searchTerm?.trim() ?? "") === "") {
      setSearchTerm(null);
    } else {
      setSearchTerm(searchTerm);
    }
    setItems([]);
    setShouldLoadItems(true);
  }, []);

  const [hasMoreItems, setHasMoreItems] = useState(true);
  useEffect(() => {
    setHasMoreItems(currentPage === 0 || continuationString != null);
  }, [currentPage, continuationString]);

  return {
    listDefinition,
    items,
    isLoading,
    hasMoreItems,
    LoadMoreItems,
    ClearItems,
    SearchItems,
  };
}
