import { object, string, ObjectSchema, array } from 'yup';

export type User = {
    Id: string;
    DisplayName: string;
    JobTitle: string;
    Mail: string;
    UserPrincipalName: string
    Department: string
}

export type ContentHubUser = {
    Id: string,
    Name: string,
    JobTitle: string,
    Email: string,
    UserPrincipalName: string,
    Department?: string | null
}

export const ContentHubUserSchema: ObjectSchema<ContentHubUser> = object({
    Id: string().defined().required("Provide Azure ID"),
    Name:  string().defined().required("Provide Name"),
    JobTitle: string().defined().required("Provide Job Title"),
    Email: string().defined().required("Provide Email"),
    UserPrincipalName: string().defined().required("Provide User Principal Name"),
    Department: string().nullable()
})