export interface Counterparty {
    CounterpartyId: number,
    Status: string,
    Name: string,
    Sic: string,
    CounterpartyClass: string
}

export interface Attachments {
    Result: AttachmentFile[] 
}

export interface AttachmentFile {
    Id: string,
    Name: string,
    WebUrl: string
}

export const counterpartyToSingleValue = (value: string | Counterparty, field: string) => {
    if(typeof value === "string"){
        return value;
    }
    const currentValue: Counterparty = value;

    switch(field){
        case "name": {
            return currentValue.Name;
        }
        
        case "counterpartyId":{
            return currentValue.CounterpartyId;
        }
    }
}