import { ContentHubItemElement } from "../../../../Services/types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../../../Services/types/ContentHub/ContentHubListsElement";
import { ContentHubSiteElement } from "../../../../Services/types/ContentHub/ContentHubSiteElement";
import { SqlQueryRequest } from "../../../../Services/types/ContentHub/IT/SqlQueryRequests";
import { ContentHubUser } from "../../../../Services/types/General/user";
import { ContentHubListRegistry } from "../../SitesRegistry";
import { SqlQueryRequestForm } from "./SqlQueryRequestForm";

export const SqlQueryRegistry: ContentHubListRegistry = {
  name: "SqlQueryRequests",
  header: "SQL Query Request",
  columns: [
        { key: 'id', displayName: 'ID', visible: false },
        { key: 'title', displayName: 'Title' },
        { key: 'sourceServer', displayName: 'Source Server' },
        { key: 'targetServer', displayName: 'Target Server' },
        { key: 'database', displayName: 'Database' },
        { key: 'status', displayName: 'Status' },
        { key: 'requestor', displayName: 'Requestor' },
  ],
  mapper: function (
    item: ContentHubItemElement<SqlQueryRequest>
  ): Record<string, any> {
    var requestor = item.Fields.requestor.Value as ContentHubUser;
    return {
      id: item.Id,
      title: item.Fields.title.Value,
      sourceServer: item.Fields.sourceServer.Value,
      targetServer: item.Fields.targetServer.Value,
      database: item.Fields.database.Value,
      status: item.Fields.status.Value,
      requestor: requestor.Name,
    };
  },
  renderForm: function (
    siteDefinition: ContentHubSiteElement,
    listDefinition: ContentHubListsElement,
    selectedItem: ContentHubItemElement<SqlQueryRequest>,
    drawerOpen: boolean,
    onDrawerClosed: (result: SqlQueryRequest | null) => void
  ): JSX.Element {
    return (
      <SqlQueryRequestForm
        site={siteDefinition}
        list={listDefinition}
        itemDefinition={selectedItem}
        drawerOpen={drawerOpen!}
        onDrawerClosed={onDrawerClosed}
      />
    );
  },
};
