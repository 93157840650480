import { useContext, useState, useEffect } from "react";
import { TeamsFxContext } from "../components/Context";
import { ContentHubService } from "../Services/contentHub/contentHub";
import { ContentHubSiteElement } from "../Services/types/ContentHub/ContentHubSiteElement";

export default function useContentHubSite(siteName: string) {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const [errorResponse, setErrorResponse] = useState<Error | undefined>();

  //Load Site Info
  const [siteInfo, setSiteInfo] = useState<ContentHubSiteElement | undefined>(
    undefined
  );
  useEffect(() => {
    (async () => {
      if (siteInfo === undefined && teamsUserCredential !== undefined) {
        try {
          var siteData = await ContentHubService.getSite(
            siteName,
            teamsUserCredential!
          );
          setSiteInfo(siteData);
        } catch (error: any) {
          setErrorResponse(error);
        }
      }
    })();
  }, [siteInfo, teamsUserCredential]);

  return { siteInfo: siteInfo, error: errorResponse };
}
