import { Button } from "@fluentui/react-components";
import { ContentHubSiteElement } from "../../../Services/types/ContentHub/ContentHubSiteElement";
import { ContentHubTable } from "../ContentHubTable/ContentHubTable";
import {
  ContentHubListRegistry,
  ContentHubSiteRegistry,
} from "../../Sites/SitesRegistry";
import { Column } from "ka-table/models";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import useContentHub from "../../../Hooks/useContentHub";
import { ContentHubItemElement } from "../../../Services/types/ContentHub/ContentHubItemElement";
import { SelectedContentHubListContext } from "../../../Context/SelectedContentHubListContext";
export default function ContentHubList<T = any>(props: {
  siteRegistry: ContentHubSiteRegistry;
  listRegistry: ContentHubListRegistry;
  siteDefinition: ContentHubSiteElement;
}) {
  const {
    listDefinition,
    items,
    isLoading,
    hasMoreItems,
    ClearItems,
    LoadMoreItems,
    SearchItems,
  } = useContentHub(props.siteDefinition.Id, props.listRegistry.name);
  useEffect(() => {
    if (listDefinition !== undefined) {
      LoadMoreItems();
    }
  }, [LoadMoreItems, listDefinition]);

  const [tableColumns, setTableColumns] = useState<Column[]>([]);

  const [mappedItems, dispatchMappedItems] = useReducer(() => {
    return items.map((i) => props.listRegistry.mapper(i));
  }, []);

  useEffect(() => dispatchMappedItems(), [items, listDefinition]);

  useEffect(() => {
    if (props.listRegistry?.columns) {
      setTableColumns(
        props.listRegistry.columns
          .map(
            (c) =>
              ({
                key: c.key,
                title: c.displayName,
                visible: c.visible ?? true,
              } as Column)
          )
          .concat([
            {
              key: "menuClick",
              style: { textAlign: "center", padding: 8 },
              width: 50,
            },
          ])
      );
    }
  }, [props.listRegistry, props.siteRegistry]);

  const [selectedContentHub, , , setSelectedContentHubItem] = useContext(
    SelectedContentHubListContext
  );

  //Drawer Methods
  const [selectedItem, setSelectedItem] =
    useState<ContentHubItemElement<T> | null>();
  const [drawerOpen, setDrawerOpen] = useState<boolean>();
  const openDrawer = useCallback(
    (itemSelected: any | null) => {
      if (itemSelected !== null && itemSelected !== undefined) {
        const selected = items.find((item) => item.Id === itemSelected.id);
        if (selected !== undefined) {
          setSelectedContentHubItem(selected.Id);
          setSelectedItem(selected);
          setDrawerOpen(true);
        }
      } else {
        setSelectedContentHubItem(null);
        setDrawerOpen(true);
      }
    },
    [items, setSelectedContentHubItem]
  );

  const closeDrawer = useCallback(
    (result: T | null) => {
      setSelectedItem(null);
      setDrawerOpen(false);
      setSelectedContentHubItem(null);
      ClearItems();
    },
    [ClearItems, setSelectedContentHubItem]
  );

  useEffect(() => {
    if (selectedContentHub.selectedItem && !isLoading) {
      openDrawer({ id: selectedContentHub.selectedItem });
    }
  }, [selectedContentHub, isLoading]);

  return (
    <div className="flexfill nooverflow">
      {listDefinition && (
        <>
          <div>
            <Button onClick={() => openDrawer(null)}>New Item</Button>
          </div>
          <div className="flexfill nooverflow">
            <ContentHubTable
              onItemClicked={openDrawer}
              onNext={() => LoadMoreItems()}
              searchTrigger={SearchItems}
              hasMoreItems={hasMoreItems}
              isLoading={isLoading}
              showEndMessage={!hasMoreItems}
              list={listDefinition}
              dataItems={mappedItems}
              tableColumns={tableColumns}
            />
          </div>
          {props.listRegistry.renderForm(
            props.siteDefinition,
            listDefinition!,
            selectedItem,
            drawerOpen!,
            closeDrawer
          )}
        </>
      )}
    </div>
  );
}
