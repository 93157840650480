import {
  Avatar,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Skeleton,
  SkeletonItem,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableRow,
  TableRowId,
  createTableColumn,
  useTableFeatures,
  Button
} from "@fluentui/react-components";
import * as React from "react";
import { ApprovalHistoryState } from "../../../Services/types/approvalHistoryElement";
import { ApprovalStatus } from "../../../Services/types/approvalRequest";
import { formatDate } from "../../../utils/utils";
import { ApprovalService } from "../../../Services/approvals/approvals";
import { TeamsFxContext } from "../../Context";
import { Collapse } from '@fluentui/react-motion-components-preview';
import './ApprovalHistory.css';

export const ApprovalHistory = ({
  correlationKey,
}: {
  correlationKey: string;
}) => {
  const { teamsUserCredential } = React.useContext(TeamsFxContext);
  const { themeString } = React.useContext(TeamsFxContext);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [itemHistory, setItemHistory] = React.useState<ApprovalHistoryState[]>(
    []
  );
  const [historyErrorFlag, setHistoryErrorFlag] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (isLoading === false) return;
    (async () => {
      try {
        if (!teamsUserCredential) {
          throw new Error("TeamsFx SDK is not initialized.");
        }
        let _itemHistory = await ApprovalService.getApprovalHistory(
          correlationKey,
          teamsUserCredential
        );
        if (_itemHistory !== undefined) {
          _itemHistory.sort(
            (a, b) =>
              (new Date(b.CreatedDate) as any) -
              (new Date(a.CreatedDate) as any)
          );
          setItemHistory(_itemHistory);
        }
      } catch (ex) {
        console.error(ex);
        setHistoryErrorFlag(true);
      }
      setIsLoading(false);
    })();
  }, [isLoading]);

  React.useEffect(() => {
    setIsLoading(true);
  }, [correlationKey]);

  //Define elements
  const tableColumns: TableColumnDefinition<ApprovalHistoryState>[] = [
    createTableColumn<ApprovalHistoryState>({
      columnId: "date",
      renderHeaderCell: () => "Date",
      renderCell: (item) => formatDate(item.CreatedDate),
    }),
    createTableColumn<ApprovalHistoryState>({
      columnId: "change",
      renderHeaderCell: () => "Change",
      renderCell: (item) => (
        <span>
          Status changed to <b>{ApprovalStatus.toString(item.Status)}</b>
        </span>
      ),
    }),
    createTableColumn<ApprovalHistoryState>({
      columnId: "approver",
      renderHeaderCell: () => "Approver",
      renderCell: (item) => {
        let displayedUser = item.Requestor;
        if (
          item.Status === ApprovalStatus.Denied ||
          item.Status === ApprovalStatus.Approved
        ) {
          displayedUser = item.Approvers[0];
        }
        return (
          <TableCellLayout
            media={
              <Avatar
                aria-label={displayedUser.Email}
                name={displayedUser.Name}
                badge={{}}
              ></Avatar>
            }
          >
            {displayedUser.Name}
          </TableCellLayout>
        );
      },
    }),
  ];

  //Comments UI
  const [displayComment, setDisplayComment] = React.useState(false);
  const showComment = () => {
    setDisplayComment(!displayComment);
  }


  const { getRows } = useTableFeatures<ApprovalHistoryState>({
    columns: tableColumns,
    items: itemHistory,
  });
  const rows = getRows();
  return (
    <>
      <Button
        style={{ marginBottom: '15px' }}
        onClick={() => showComment()}
      >
       Display/Hide Comments
      </Button>
      {!isLoading && !historyErrorFlag && (
        <Table size="extra-small">
          <TableBody>
            {rows.map((row, index) => (
              <>
                <TableRow key={row.rowId}>
                  {tableColumns.map((column) => (
                    <TableCell key={column.columnId} onClick={() => showComment()}>
                      {column.renderCell(row.item)}
                    </TableCell>
                  ))}
                </TableRow>
                <Collapse visible={displayComment}>
                  <div className="historyComment" style={{ color: themeString === 'dark' ? '#ffffff' : '#292929' }}>
                    <p style={{ marginLeft: '10px' }}>{row.item.Comment}</p>
                  </div>
                </Collapse>
              </>
            ))}
          </TableBody>
        </Table>
      )}

      {historyErrorFlag && (
        <MessageBar intent="error">
          <MessageBarBody>
            <MessageBarTitle>Error</MessageBarTitle>
            The history could not be retrieved from the server.
          </MessageBarBody>
        </MessageBar>
      )}

      {isLoading && (
        <Skeleton>
          {Array(3)
            .fill(null)
            .map((_, i) => (
              <div
                key={i}
                style={{
                  marginBottom: "4px",
                  marginTop: "4px",
                  display: "grid",
                  gridTemplateColumns: "20ch 20ch min-content",
                  justifyContent: "space-between",
                }}
              >
                <SkeletonItem shape="rectangle" />
                <SkeletonItem shape="rectangle" />
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "min-content 20ch",
                    gap: "5px",
                    justifyContent: "end",
                  }}
                >
                  <SkeletonItem shape="circle" />
                  <SkeletonItem shape="rectangle" />
                </div>
              </div>
            ))}
        </Skeleton>
      )}
    </>
  );
};
