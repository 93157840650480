import { dropDownToString } from '../../General/DropDownData';
import { object, string, ObjectSchema, array, Schema, boolean, date } from 'yup';
import { ContentHubAttachments, ContentHubAttachmentsSchema } from '../ContentHubAttachments';

export type SqlQueryStatus = 'Pending' | 'Approved' | 'Denied' | 'In Progress' | 'DBA Completed' | 'Code Failed' | 'Cancelled';
export type SqlQueryBusiness = 'Solutions' | 'Champion';

export interface SqlQueryRequest {
    targetServer: string,
    sourceServer: string,
    database: string,
    request: string,
    approvers: object,
    requestor: object,
    ticketNumber: string,
    status: SqlQueryStatus,
    businessUnit: SqlQueryBusiness,
    title: string,    
    _attachments: ContentHubAttachments | null,
    _workflowStep: string
}

export const SqlQueryRequestSchema: ObjectSchema<SqlQueryRequest> = object({
    targetServer: string().defined().required("Provide Target Server"),
    sourceServer: string().defined().default(''),
    database: string().defined().required("Provide a database name"),
    request: string().defined().default(''),
    approvers: array<object>().required("Provide one or more approvers for this request"),
    requestor: object().required('Provide a requestor'),
    ticketNumber: string().defined().default(''),
    status: string<SqlQueryStatus>().default('Pending').
        transform((value) => dropDownToString(value)),
    businessUnit: string<SqlQueryBusiness>().default('Solutions').
        transform((value) => dropDownToString(value)),
    title: string().required("Provide a title for the request"),   
    _attachments:  ContentHubAttachmentsSchema.nullable(),
    _workflowStep: string().defined().default('NewRequest')
})