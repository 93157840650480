import config from "../../components/sample/lib/config";
import { BearerTokenAuthProvider, createApiClient, TeamsUserCredential } from "@microsoft/teamsfx";
import { Attachments, AttachmentFile } from "../types/SharePoint/SharePointDriveItems";

const apiBaseUrl = config.apiEndpoint + "/api/";

interface ResultData {
    Result: string
}

async function getToken(credential: TeamsUserCredential) {
    return (await credential.getToken(""))!.token;
}

function getApiClient(credential: TeamsUserCredential) {
    const apiClient = createApiClient(
        apiBaseUrl,
        new BearerTokenAuthProvider(() => getToken(credential))
    );
    return apiClient;
}

export namespace SharePointDriveService {
    export async function uploadFiles(item: string, list: string, files: File[], teamsUserCredential: TeamsUserCredential) {
        try {
            const apiClient = getApiClient(teamsUserCredential);
            let data = new FormData();

            for (let i = 0; i < files.length; i++) {
                data.append(files[i].name, files[i])
            }

            var folderId = await apiClient.post("postSharePointFiles", data, {
                params: {
                    'item': item,
                    'list': list
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return folderId.data as string;
        }
        catch (ex) {
            console.log(ex);
        }
    }

    export async function getAttachments(
        list: string,
        folderId: string,
        teamsUserCredential: TeamsUserCredential
    ) {
        try {
            const apiClient = getApiClient(teamsUserCredential);
            const response = await apiClient.get("getAttachments", {
                params: {
                    'list': list,
                    'folderId': folderId
                }
            })

            return response.data as AttachmentFile[];
        }
        catch (ex) {
            console.log(ex);
        }
    }

    export async function deleteAttahcment(
        list: string,
        itemId: string,
        teamsUserCredential: TeamsUserCredential
    ) {
        try {
            const apiClient = getApiClient(teamsUserCredential);

            await apiClient.delete("deleteAttachment", {
                params: {
                    'list': list,
                    'fileId': itemId
                }
            });

            return true;
        }
        catch (ex) {
            console.log(ex)
        }
    }
}

