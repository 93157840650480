import "./Welcome.css";
import { useContext, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { TeamsFxContext } from "./Context";
import { ContentHubSite } from "./ContentHubComponents/ContentHubSite/ContentHubSite";
import { DeepLinkContext } from "../Context/DeepLinkContext";
import { matchPath } from "react-router-dom";
import { SelectedContentHubListContext } from "../Context/SelectedContentHubListContext";
import useSelectedContentHub from "../Hooks/useSelectedContentHub";
import { ContentHubSitesRegistry } from "./Sites/SitesRegistry";

//Get Info From MS Graph Function App
export default function Workflows() {
  const { themeString } = useContext(TeamsFxContext);
  const [deepLink, setDeepLink] = useContext(DeepLinkContext);

  const [
    selectedContentHub,
    setSelectedContentHubSite,
    setSelectedContentHubList,
    setSelectedContentHubItem,
  ] = useSelectedContentHub();

  useEffect(() => {
    let link = deepLink;
    if (link == null) return;
    let locationMatch = matchPath(
      "/workflows/:siteName?/:listName?/:itemId?",
      deepLink
    );
    console.log(locationMatch);
    let siteName = locationMatch?.params["siteName"];
    let listName = locationMatch?.params["listName"];
    let itemId = locationMatch?.params["itemId"];
    setSelectedContentHubSite(siteName ?? null);
    setSelectedContentHubList(listName ?? null);
    setSelectedContentHubItem(itemId ?? null);
    setDeepLink(null);
  }, [deepLink]);

  const selectSite = (siteName: string) => {
    setSelectedContentHubSite(siteName);
    setSelectedContentHubList(null);
    setSelectedContentHubItem(null);
  };

  const selectList = (listName: string) => {
    setSelectedContentHubList(listName);
    setSelectedContentHubItem(null);
  };

  return (
    <SelectedContentHubListContext.Provider
      value={[
        selectedContentHub,
        setSelectedContentHubSite,
        setSelectedContentHubList,
        setSelectedContentHubItem,
      ]}
    >
      <div
        className={
          themeString === "default"
            ? "light"
            : themeString === "dark"
            ? "dark"
            : "contrast"
        }
      >
        <div className="flexfill nooverflow">
          <div className="narrow page-padding">
            <Breadcrumb size="large">
              <BreadcrumbItem>
                <Menu>
                  <MenuTrigger>
                    {selectedContentHub.selectedSite ? (
                      <BreadcrumbButton>
                        {
                          ContentHubSitesRegistry.find(
                            (s) => s.name === selectedContentHub.selectedSite
                          )?.text
                        }
                      </BreadcrumbButton>
                    ) : (
                      <BreadcrumbButton>
                        <b>Select a site</b>
                      </BreadcrumbButton>
                    )}
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      {ContentHubSitesRegistry.map((s) => (
                        <MenuItem
                          key={s.name}
                          onClick={() => selectSite(s.name)}
                        >
                          {s.text}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </MenuPopover>
                </Menu>
              </BreadcrumbItem>
              {selectedContentHub.selectedSite && (
                <>
                  <BreadcrumbDivider />
                  <BreadcrumbItem>
                    <Menu>
                      <MenuTrigger>
                        {selectedContentHub.selectedList ? (
                          <BreadcrumbButton>
                            {
                              ContentHubSitesRegistry.find(
                                (s) =>
                                  s.name === selectedContentHub.selectedSite
                              )?.lists.find(
                                (l) =>
                                  l.name === selectedContentHub.selectedList
                              )?.header
                            }
                          </BreadcrumbButton>
                        ) : (
                          <BreadcrumbButton>
                            <b>Select a list</b>
                          </BreadcrumbButton>
                        )}
                      </MenuTrigger>
                      <MenuPopover>
                        <MenuList>
                          {ContentHubSitesRegistry.find(
                            (s) => s.name === selectedContentHub.selectedSite
                          )?.lists.map((s) => (
                            <MenuItem
                              key={s.name}
                              onClick={() => selectList(s.name)}
                            >
                              {s.header}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </BreadcrumbItem>
                </>
              )}
            </Breadcrumb>
          </div>
          <div className="flexfill nooverflow">
            {ContentHubSitesRegistry.filter(
              (s) => s.name === selectedContentHub.selectedSite
            ).map((s) => (
              <ContentHubSite siteRegistry={s} key={s.name}></ContentHubSite>
            ))}
          </div>
        </div>
      </div>
    </SelectedContentHubListContext.Provider>
  );
}
