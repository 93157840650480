import { dropDownToString } from '../../General/DropDownData';
import { counterpartyToSingleValue } from '../../General/Counterparty'
import { object, string, ObjectSchema, array, Schema, boolean, date, number } from 'yup';
import { ContentHubUserSchema , ContentHubUser} from '../../General/user';

export type BadDebtRmAccountStatus = 'Acive' | 'Final' | 'Pending Final';
export type BadDebtGlStatus = '110505' | '110510';
export type BadDebtReasonCode = 'BKY' | 'POL' | 'TRM' | 'CAM';
export type BadDebtRegion = 'NE' | 'W' | 'G' | 'MW';
export type BadDebtReserved = 'Partial' | 'No' | 'Fully';
export type BadDebtReviewStatus = 'Pending' | 'Approved' | 'Revision Required' | 'Denied' | 'N/A';

export interface BadDebt {
    counterparty: string,
    counterpartyId: string,
    accountNumber: string,
    rmAccountStatus: BadDebtRmAccountStatus,
    collector: ContentHubUser,
    glNumber: BadDebtGlStatus,
    electricity: number,
    gas: number,
    terminationValue: number,
    writeOffTotal: number,
    writeOffSummary: string,
    creditApprovalDetails: string,
    reasonCode: BadDebtReasonCode,
    fillingDate?: Date | null | undefined,
    eventRelated: boolean,
    eventDetails: string,
    salesPerson: ContentHubUser,
    rvp: ContentHubUser,
    region: BadDebtRegion,
    counterpartyClass: string,
    sicCode: string,
    reserved: BadDebtReserved,
    requestor: ContentHubUser,
    requestStatus: string,
    // managerCreditRisk?: object | null,
    // managerCreditRiskReview?: BadDebtReviewStatus,
    // managerCreditRiskApprovalDate?: string | null,
    // managerCreditRiskComments?: string | null,
    // managingDirectorCreditRisk?: object | null,
    // managingDirectorCreditRiskReview?: BadDebtReviewStatus,
    // managingDirectorCreditRiskApprovalDate?: string | null,
    // managingDirectorCreditRiskComments?: string | null,
    // svp?: object | null,
    // svpReview?: BadDebtReviewStatus,
    // svpApprovalDate?: string | null,
    // svpComments?: string | null,
    // president?: object | null,
    // presidentReview: BadDebtReviewStatus,
    // presidentApprovalDate?: string | null,
    // presidentComments: string,
    accountingReview?: object | null,
    accountingStatus: BadDebtReviewStatus,
    accountingComments: string,
    journalEntryNumber?: string | undefined,
    dateCompleted?: Date | null
}

export const BadDebtSchema: ObjectSchema<BadDebt> = object({
    counterparty: string().defined().required('Select a Counterparty')
        .transform((value) => counterpartyToSingleValue(value, 'name')).min(1),
    counterpartyId: string().required('Provide Counterparty ID'),
    accountNumber: string().required('Provide account number'),
    rmAccountStatus: string<BadDebtRmAccountStatus>().required()
        .transform((value) => dropDownToString(value)),
    collector: ContentHubUserSchema,
    glNumber: string<BadDebtGlStatus>().required('Select a GL Number').default('110505')
        .transform((value) => dropDownToString(value)),
    electricity: number().required('').default(0.00),
    gas: number().required('').default(0.00),
    terminationValue: number().required('').default(0.00),
    writeOffTotal: number().required('').default(0.00),
    writeOffSummary: string().required('Provide a Summary').default('0'),
    creditApprovalDetails: string().required('Provide Details'),
    reasonCode: string<BadDebtReasonCode>().required('Select a reason code')
        .transform((value) => dropDownToString(value)),
    fillingDate: date().defined()
        .when('reasonCode', {
            is: 'BKY',
            then: (schema) => schema.required('Provide a filling date'),
            otherwise: (schema) => schema.notRequired().nullable()
        }),
    eventRelated: boolean().default(false).required("SELECT AN OPTION"),
    eventDetails: string().defined()
        .when('eventRelated', {
            is: true,
            then: (schema) => schema.required('Provide Event Details'),
            otherwise: (schema) => schema.notRequired().default('')
        }),
    salesPerson: ContentHubUserSchema,
    rvp: ContentHubUserSchema,
    region: string<BadDebtRegion>().required('Select a Region')
        .transform((value) => dropDownToString(value)),
    counterpartyClass: string().required('Select a Counterparty Class'),
    sicCode: string().required('Provide a SIC Code'),
    reserved: string<BadDebtReserved>().required('Select a Reserved Option')
        .transform((value) => dropDownToString(value)),
    requestor: ContentHubUserSchema,
    requestStatus: string().required().default('Pending'),
    // managerCreditRisk: object().notRequired().defined(),
    // managerCreditRiskReview: string<BadDebtReviewStatus>().default('N/A')
    //     .transform((value) => dropDownToString(value)),
    // managerCreditRiskApprovalDate: string().notRequired().nullable(),
    // managerCreditRiskComments: string().defined()
    //     .when('managerCreditRiskReview', {
    //         is: 'Revision Required',
    //         then: (schema) => schema.required('Provide comments'),
    //         otherwise: (schema) => schema.notRequired().nullable()
    //     }),
    // managingDirectorCreditRisk: object().notRequired(),
    // managingDirectorCreditRiskReview: string<BadDebtReviewStatus>().default('N/A')
    //     .transform((value) => dropDownToString(value)),
    // managingDirectorCreditRiskApprovalDate: string().notRequired().nullable(),
    // managingDirectorCreditRiskComments: string().defined()
    //     .when('managingDirectorCreditRiskReview', {
    //         is: 'Revision Required',
    //         then: (schema) => schema.required('Provide comments'),
    //         otherwise: (schema) => schema.notRequired().nullable()
    //     }),
    // svp: object().notRequired().nullable(),
    // svpReview: string<BadDebtReviewStatus>().default('N/A')
    //     .transform((value) => dropDownToString(value)),
    // svpApprovalDate: string().notRequired().nullable(),
    // svpComments: string().defined()
    //     .when('svpReview', {
    //         is: 'Revision Required',
    //         then: (schema) => schema.required('Provide comments'),
    //         otherwise: (schema) => schema.notRequired().nullable()
    //     }),
    // president: object().notRequired(),
    // presidentReview: string<BadDebtReviewStatus>().default('N/A')
    //     .transform((value) => dropDownToString(value)),
    // presidentApprovalDate: string().notRequired().nullable(),
    // presidentComments: string().defined()
    //     .when('presidentReview', {
    //         is: 'Revision Required',
    //         then: (schema) => schema.required('Provide comments'),
    //         otherwise: (schema) => schema.notRequired().nullable()
    //     }),
    accountingReview: object().notRequired().defined(),
    accountingStatus: string<BadDebtReviewStatus>().default('N/A')
        .transform((value) => dropDownToString(value)),
    accountingComments: string().default(''),
    journalEntryNumber: string().defined().default('')
        .when('accountingStatus', {
            is: 'Completed',
            then: (schema) => schema.required('Provide a Journal Entry Number'),
            otherwise: (schema) => schema.notRequired().nullable()
        }),
    dateCompleted: date().notRequired().nullable()
})