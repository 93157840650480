import { useState, useEffect, useContext } from "react";
import { Caption1, Tooltip, Card, CardHeader, MessageBar, MessageBarBody, MessageBarTitle, Skeleton, SkeletonItem, OverlayDrawer, DialogOpenChangeEvent, DialogOpenChangeData, DrawerHeader, DrawerHeaderTitle, Button, DrawerBody, Field, Input, Label, Textarea, Accordion, AccordionHeader, AccordionPanel, AccordionItem } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { FileUploader } from "react-drag-drop-files";
import {
    Dismiss12Filled,
    FluentIconsProps
} from "@fluentui/react-icons";

import { SharePointDriveService } from "../../../Services/sharepoint/sharePointDrive";
import { TeamsFxContext } from "../../Context";
import { AttachmentFile, Attachments } from "../../../Services/types/SharePoint/SharePointDriveItems";
import '../FileUploader/FileUploaderCommon.css'
import { ContentHubAttachments } from "../../../Services/types/ContentHub/ContentHubAttachments";

export const FileUploaderCommon = ({
    reload,
    attachment,
    listName,
    filesOnForm,
    fileTypes
}: {
    reload: boolean,
    attachment: ContentHubAttachments
    listName: string,
    filesOnForm?: (value: any) => void,
    fileTypes?: string[] | null | undefined
}) => {
    const { teamsUserCredential } = useContext(TeamsFxContext);

    //Files    
    const [fileOrFiles, setFile] = useState(null); //Used by Component FileUploader to set Files
    const [attachmentName, setAttachmentsName] = useState<File[]>([]); //Files to be uploaded
    const [attachedFiles, setAttachedFiles] = useState<AttachmentFile[] | undefined>(undefined); //Files already in Drive

    //On Load
    useEffect(() => {
        console.log(fileTypes);
        loadAttachments();
    }, [])

    //Reload Files
    useEffect(() => {
        if (reload) {
            loadAttachments();
        }
    }, [attachment])

    //File Uploader Methods
    const filesAttached = (filesData: FileList) => {

        //Attach Names to Display
        let arrayName: File[] = [];
        for (let i = 0; i < filesData.length; i++) {
            arrayName.push(filesData[i]);
        }
        setAttachmentsName(arrayName);

        //Set Files Objects on Form
        filesOnForm?.(arrayName);
    }

    //Load Files
    const loadAttachments = async () => {
        try {
            setAttachedFiles(undefined);
            setAttachmentsName([]);

            if (attachment !== undefined && attachment !== null) {
                const driveId = attachment.ItemFolderId;

                if (driveId !== null && driveId !== undefined && driveId !== '') {
                    const filesInDrive = await SharePointDriveService.getAttachments(listName, driveId, teamsUserCredential!)
                    setAttachedFiles(filesInDrive);
                }
                else {
                    const empty: AttachmentFile[] = [];
                    setAttachedFiles(empty);
                }
            }
            else {
                const empty: AttachmentFile[] = [];
                setAttachedFiles(empty);
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    //Delete Files from Drive
    const deleteAttachedFile = async (event: any, list: string, itemId: string) => {
        event.stopPropagation();

        await SharePointDriveService.deleteAttahcment(list, itemId, teamsUserCredential!);
        loadAttachments();
    }

    //Remove File From Array
    const removeFile = (fileName: string) => {
        const currentFiles = attachmentName;

        let newFiles = currentFiles?.filter(file => { return file.name !== fileName });
        setAttachmentsName(newFiles);
        filesOnForm?.(newFiles);
    }

    const iconStyleProps: FluentIconsProps = {
        primaryFill: "red",
        className: "iconClass"
    };


    return (
        <div className="attachmentsArea">


            <div id="filesDiv">
                <h3>Files Attached:</h3>
                {
                    attachedFiles ?
                        (
                            attachedFiles.length > 0 ?
                                (
                                    attachedFiles.map(file => {
                                        return (
                                            <div id={file.Id}>
                                                <Card
                                                    className="fileCard"
                                                    onClick={() => window.open(file.WebUrl, '_blank')}
                                                >
                                                    <CardHeader
                                                        header={file.Name}
                                                        description={
                                                            <Caption1>Download</Caption1>
                                                        }
                                                        action={
                                                            <Tooltip content="Delete File" relationship="label">
                                                                <Button
                                                                    icon={<Dismiss12Filled
                                                                        {...iconStyleProps}
                                                                    >
                                                                    </Dismiss12Filled>}
                                                                    onClick={(event) => deleteAttachedFile(event, listName, file.Id)}
                                                                >
                                                                </Button>
                                                            </Tooltip>
                                                        }
                                                    >
                                                    </CardHeader>
                                                </Card>
                                            </div>
                                        )
                                    })
                                ) :
                                (
                                    <MessageBar key="info-attachments" intent="info">
                                        <MessageBarBody>
                                            <MessageBarTitle>Attachments Files: </MessageBarTitle>
                                            No attachments have been added to this ticket.
                                        </MessageBarBody>
                                    </MessageBar>
                                )
                        ) : (
                            <Skeleton>
                                {Array(3)
                                    .fill(null)
                                    .map((_, i) => (
                                        <div
                                            key={i}
                                            style={{
                                                marginBottom: "4px",
                                                marginTop: "4px",
                                                display: "grid",
                                                gridTemplateColumns: "20ch 20ch min-content",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <SkeletonItem shape="rectangle" />
                                            <SkeletonItem shape="rectangle" />
                                        </div>
                                    ))}
                            </Skeleton>
                        )
                }
            </div>

            <div className="fileUploaderDiv">
                <Label htmlFor="attachments">
                    Add Attachments:
                </Label>
                <FileUploader
                    fileOrFiles={fileOrFiles}
                    multiple={true}
                    handleChange={filesAttached}
                    Name="attachments"
                    types={fileTypes}
                    dropMessageStyle={{ backgroundColor: '#464EB8' }}
                />
            </div>


            <div id="newfiles">

                {
                    attachmentName ?
                        (
                            attachmentName.map(file => {
                                return (
                                    <div>
                                        <div>
                                            <h3>Files To be Uploaded:</h3>
                                        </div>
                                        <div>
                                            <Card className="newFile">
                                                <CardHeader
                                                    header={file.name}
                                                    description={file.size}
                                                    action={
                                                        <Tooltip content="Remove attachment" relationship="label">
                                                            <Button
                                                                icon={<Dismiss12Filled {...iconStyleProps} />}
                                                                appearance="transparent"
                                                                aria-label="More options"
                                                                shape="circular"
                                                                onClick={() => removeFile(file.name)}
                                                            >
                                                            </Button>
                                                        </Tooltip>
                                                    }
                                                >
                                                </CardHeader>
                                            </Card>
                                        </div>
                                    </div>
                                )
                            })
                        ) :
                        (
                            <div></div>
                        )
                }
            </div>
        </div>
    )
}