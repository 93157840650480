
import { StylesConfig, ThemeConfig } from "react-select";


export function getSelectStyle(themeString: string): StylesConfig<any> {
    const customStyle: StylesConfig<any> = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            color: state.isFocused ? '#ffffff' : '#ffffff',
            borderColor: state.isFocused ? '#464EB8' : '#A9A9A9',
            backgroundColor: themeString === 'dark' ? '#292929' : '#ffffff'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: ((state.isSelected || state.isFocused) ? '#464EB8' : (themeString === 'dark' ? '#292929' : '#ffffff')),
            '&:hover': {
                backgroundColor: state.isSelected ? '#464EB8' : '#464EB8',
                color: state.isSelected ? '#ffffff' : '#ffffff'
            },
            color: ((state.isSelected) ? '#ffffff' : (themeString === 'dark' ? '#ffffff' : '#292929')),
            borderColor: themeString === 'dark' ? '#292929' : '#ffffff',
        }),
        singleValue: (base, props) => ({
            ...base,
            color: themeString === 'dark' ? '#ffffff' : '#292929',
            //backgroundColor: themeString === 'dark' ? '#ffffff' : '#292929',
        }),
        noOptionsMessage: (base, props) => ({
            ...base,
            backgroundColor: themeString === 'dark' ? '#292929' : '#ffffff',
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#FF0000",
        }),
        menuList: (provided, state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: themeString === 'dark' ? '#292929' : '#ffffff',
            color: themeString === 'dark' ? '#ffffff' : '#292929',
        }),
        input: (base, state) => ({
            ...base,
            color: themeString === 'dark' ? '#ffffff' : '#292929',

        }),
        multiValue: (base, sate) => ({
            ...base,
            //color: themeString === 'dark' ? '#ffffff' : '#292929',
            backgroundColor: themeString === 'dark' ? '#545454' : '#d9dade',
        }),
        multiValueLabel: (base, state) => ({
            ...base,
            color: themeString === 'dark' ? '#ffffff' : '#292929',
        }),
        multiValueRemove: (base, state) => ({
            ...base,
            backgroundColor: '#8b90d6',
            '&:hover': {
                backgroundColor: '#464EB8',
                color: '#ffffff'
            },
        })
    }

    return customStyle;
};

export function getSelectTheme(themeString: string): ThemeConfig {
    const customTheme: ThemeConfig = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary: '#464EB8',
            color: themeString === 'dark' ? '#292929' : '#ffffff',
            text: themeString === 'dark' ? '#292929' : '#ffffff',
        }
    });

    return customTheme;
}
