import { Field } from "@fluentui/react-components"
import { Control, FieldValues, useController, Path } from "react-hook-form"
import { getSelectStyle, getSelectTheme } from "../CommonSelect/CommonSelect"
import { useContext, useState } from "react"
import { TeamsFxContext } from "../../Context"
import { DropDownData } from "../../../Services/types/General/DropDownData"
import Select from "react-select";

export const SelectDropDownData = <T extends FieldValues>({ label, options, control, name, required }: {
    label: string,
    options: DropDownData[],
    control: Control<T>,
    name: Path<T>,
    required?: boolean
}) => {
    const [requiredField, setRequiredField] = useState(required);
    const { themeString } = useContext(TeamsFxContext);
    const { field } = useController({
        control,
        name
    });

    return (
        <Field label={label} required={requiredField !== undefined ? requiredField : false}>
            <Select
                {...field}
                styles={getSelectStyle(themeString)}
                theme={getSelectTheme(themeString)}
                options={options}
                getOptionValue={option => `${option.value}`}
                getOptionLabel={option => `${option.label}`}
                value={options.find((c) => c.value === field.value)}
                isSearchable={true}
                isClearable={true}
            />
        </Field>
    )

}