import { createContext } from "react";
export const SelectedContentHubListContext = createContext<
  [
    {
      selectedSite: string | null;
      selectedList: string | null;
      selectedItem: string | null;
    },
    (siteName: string | null) => void,
    (listName: string | null) => void,
    (itemId: string | null) => void
  ]
>([
  { selectedSite: null, selectedList: null, selectedItem: null },
  (siteName: string | null) => {},
  (listName: string | null) => {},
  (itemId: string | null) => {},
]);
